<template>
	<div class="effect">
		自定义测评 敬请期待
	</div>
</template>
<script>
	export default {
		name: "survey_more"
	}
</script>
<style lang="less" scoped>
</style>