<template>
    <div class="vip">
        <div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
            <div class="operation" style="display: flex;align-items: center;justify-content: flex-start;">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入会员手机号或姓名" clearable @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-checkbox v-model="only_vip" @change="onSearchClick">仅会员（不显示教练、员工）</el-checkbox>
            </div>
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="头像" width="80">
                <template slot-scope="scope">
                    <el-image fit="contain" :src="scope.row.vip.avatar_url" style="width: 60px;height: 60px;border-radius: 5px;"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="账号信息">
                <template slot-scope="scope">
                    <div>姓名：{{ scope.row.vip.name }}（{{scope.row.vip.nickname}} {{ scope.row.vip.sex == 1 ? "男" : "女" }}）</div>
                    <div>手机：{{ scope.row.vip.phoneno }}</div>
                    <div>生日：{{ scope.row.vip.birth_dt | formatDate}}</div>
                </template>
            </el-table-column>
            <el-table-column label="加入场馆">
                <template slot-scope="scope">
                    <div>加入时间：{{scope.row.create_dt | formatDate}}</div>
                    <div v-if="1==scope.row.is_trainer || 1==scope.row.is_adminer">
                        <div>是否教练：{{1==scope.row.is_trainer?'是':'否'}}</div>
                        <div>是否员工：{{1==scope.row.is_adminer?'是':'否'}}</div>
                    </div>
                    <div v-else>
                        <div>负责教练：<span v-if="scope.row.trainer"> {{ scope.row.trainer.name}}({{scope.row.trainer.nickname}} {{scope.row.trainer.phoneno|formatPhoneno}})</span></div>
                        <div>第一教练：<span v-if="scope.row.trainer_1st"> {{ scope.row.trainer_1st.name}}({{scope.row.trainer_1st.nickname}} {{scope.row.trainer_1st.phoneno|formatPhoneno}})</span></div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="50">
                <template slot-scope="scope">
                    <span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="0==scope.row.status" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
                        <el-button v-bind:disabled="0==scope.row.status" type="danger" size="small" @click="onStatus(scope.row.id, 0)">删除</el-button>
                        <el-button v-bind:disabled="1==scope.row.status" type="success" size="small" @click="onStatus(scope.row.id, 1)">正常</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
                </el-pagination>
            </div>
        </div>

        <el-dialog class="form-wrap" :title="showAddModal ? '新增会员' : '修改会员'" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form class="form-wrap" @submit.native.prevent ref="user-form" :rules="rule" :model="mguOnSet" label-position="left" style="padding: 30px 10px">
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="手机号" prop="vip_phoneno">
                            <el-input v-model="mguOnSet.vip_phoneno" :disabled="showSetModal" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item :label="'收获奖章 '+mguOnSet.medal_count+'枚'" prop="medal_count">
                            <el-image v-for="(item,ix) in 5" :key="ix" src="https://dalisfit-gym.oss-cn-hangzhou.aliyuncs.com/static/medal.png" fit="contain" style="width:38px;height:38px;" :style="{'filter':ix>=mguOnSet.medal_count?'grayscale(100%)':''}" @click="mguOnSet.medal_count = (1+ix)" />
                        </el-form-item>
                        <!-- <el-form-item label="微信OPENID" prop="vip_wechat_openid">
                            <el-input v-model="mguOnSet.vip_wechat_openid" :disabled="true" />
                        </el-form-item> -->
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="姓名" prop="vip_name">
                            <el-input v-model="mguOnSet.vip_name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="昵称/英文名">
                            <el-input v-model="mguOnSet.vip_nickname" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="1">
                        <el-form-item label="性别">
                            <el-radio-group v-model="mguOnSet.vip_sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="0">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="是否教练">
                            <el-radio-group v-model="mguOnSet.is_trainer">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="是否员工">
                            <el-radio-group v-model="mguOnSet.is_adminer">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="1">
                        <el-form-item label="出生生日" prop="birth_dt">
                            <el-date-picker v-model="mguOnSet.vip_birth_dt" value-format="timestamp" type="date" placeholder="选择日期" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="主教练">
                            <el-select v-model="mguOnSet.trainer_id" filterable remote clearable placeholder="请输入教练姓名或手机号" :remote-method="remoteMethod" :loading="loading" style="width: 100%;">
                                <el-option v-for="item in trainer_opts" :key="item.id" :label="item.name+' '+item.phoneno" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="第一教练">
                            <el-select v-model="mguOnSet.trainer_id_1st" filterable remote clearable placeholder="请输入教练姓名或手机号" :remote-method="remoteMethod" :loading="loading" style="width: 100%;">
                                <el-option v-for="item in trainer_opts" :key="item.id" :label="item.name+' '+item.phoneno" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:50px;">
                    <el-col :span="22" :offset="1" style="display:flex;align-items: center;justify-content: space-between;">
                        <el-form-item label="">
                            <el-button plain @click="initPassword">重置密码</el-button>
                            <el-button plain @click="initPayPassword">重置支付密码</el-button>
                        </el-form-item>
                        <el-form-item label="" style="float: right;">
                            <el-button plain @click="onCloseModal">取消</el-button>
                            <el-button type="primary" @click="onSave">保存设置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
    import {
        hex,
        hex_hmac
    } from "@/utils/hex.js"
    import {
        mapState
    } from "vuex"
    import { randStr } from "@/utils/formatData"
    const defaultMgu = {}
    const defaultTrainerOpts = [{
        id: 0,
        name: '无教练',
        phoneno: ''
    }]
    export default {
        name: "user_vip",
        computed: {
            ...mapState(["user"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            },
        },
        data() {
            return {
                gym_id: 0,

                only_vip: false,

                cdit: "",
                loading: false,
                list: [],
                status: "1",
                total: 0,
                page_ix: 1,
                page_size: 10,

                mguOnSet: { ...defaultMgu },
                trainer_opts: defaultTrainerOpts,
                showAddModal: false,
                showSetModal: false,
                rule: {
                    vip_name: [{
                        required: true,
                        message: '请输入会员姓名',
                        trigger: 'blur'
                    }],
                    vip_phoneno: [{
                        required: true,
                        message: '请输入会员手机号',
                        trigger: 'blur'
                    }]
                }
            };
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                let _data = {
                    gym_id: this.gym_id,
                    cdit: this.cdit,
                    status: this.status,
                    page_ix: this.page_ix,
                    page_size: this.page_size
                }
                if (this.only_vip) {
                    _data['is_trainer'] = 0
                    _data['is_adminer'] = 0
                }
                this.$post("/admin/User/queryVip", _data).then(res => {
                    if (res.rst == 0) {
                        this.list = res.data.data;
                        this.total = res.data.total;
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            onSearchClick() {
                this.page_ix = 1;
                this.getList();
            },
            onPageChange(page) {
                this.page_ix = page;
                this.getList();
            },
            goSet(mgu) {
                this.mguOnSet = {
                    vip_id: mgu.vip.id,
                    vip_phoneno: mgu.vip.phoneno,
                    vip_wechat_openid: mgu.vip.wechat_openid,
                    vip_name: mgu.vip.name,
                    vip_nickname: mgu.vip.nickname,
                    vip_sex: mgu.vip.sex,
                    vip_birth_dt: mgu.vip.birth_dt ? 1000 * mgu.vip.birth_dt : '',
                    vip_password: '',
                    vip_pay_password: '',
                    mgu_id: mgu.id,
                    medal_count: mgu.medal_count,
                    is_trainer: mgu.is_trainer,
                    is_adminer: mgu.is_adminer,
                    trainer_id: mgu.trainer_id,
                    trainer_id_1st: mgu.trainer_id_1st
                }
                if (mgu.trainer_id || mgu.trainer_id_1st) {
                    if (mgu.trainer_id) {
                        if (mgu.trainer_id_1st) {
                            this.trainer_opts = [{
                                id: mgu.trainer_id,
                                name: (mgu.trainer && mgu.trainer.name) ? mgu.trainer.name : '未知教练',
                                phoneno: (mgu.trainer && mgu.trainer.phoneno) ? mgu.trainer.phoneno : ''
                            }]
                            if (mgu.trainer_id != mgu.trainer_id_1st) {
                                this.trainer_opts.push({
                                    id: mgu.trainer_id_1st,
                                    name: (mgu.trainer_1st && mgu.trainer_1st.name) ? mgu.trainer_1st.name : '未知教练',
                                    phoneno: (mgu.trainer_1st && mgu.trainer_1st.phoneno) ? mgu.trainer_1st.phoneno : ''
                                })
                            }
                        } else {
                            this.trainer_opts = defaultTrainerOpts
                            this.trainer_opts.push({
                                id: mgu.trainer_id,
                                name: (mgu.trainer && mgu.trainer.name) ? mgu.trainer.name : '未知教练',
                                phoneno: (mgu.trainer && mgu.trainer.phoneno) ? mgu.trainer.phoneno : ''
                            })
                        }
                    } else {
                        this.trainer_opts = defaultTrainerOpts
                        this.trainer_opts.push({
                            id: mgu.trainer_id_1st,
                            name: (mgu.trainer_1st && mgu.trainer_1st.name) ? mgu.trainer_1st.name : '未知教练',
                            phoneno: (mgu.trainer_1st && mgu.trainer_1st.phoneno) ? mgu.trainer_1st.phoneno : ''
                        })
                    }
                } else {
                    this.trainer_opts = defaultTrainerOpts
                }
                this.showSetModal = true;
            },
            remoteMethod(query) {
                setTimeout(() => {
                    this.loading = true;
                    this.$post("/admin/User/remoteTrainers", { gym_id: this.gym_id, cdit: query }).then(res => {
                        if (res.rst == 0) {
                            this.trainer_opts = res.data;
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                }, 250)
            },
            initPassword() {
                const pw = randStr(8)
                this.$confirm("该操作将重置登陆密码为 " + pw + "，确认记录后继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/User/saveVip", {
                        vip_id: this.mguOnSet.vip_id,
                        password: hex_hmac(String(this.mguOnSet.vip_wechat_openid), hex(pw))
                    }).finally(() => {
                        this.onCloseModal();
                    });
                })
            },
            initPayPassword() {
                const pw = randStr(8)
                this.$confirm("该操作将重置支付密码为 " + pw + "，确认记录后继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/User/saveVip", {
                        vip_id: this.mguOnSet.vip_id,
                        pay_password: hex_hmac(String(this.mguOnSet.vip_wechat_openid), hex(pw))
                    }).finally(() => {
                        this.onCloseModal();
                    });
                })
            },
            onSave() {
                this.$refs['user-form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("该操作将修改会员信息，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            this.loading = true;
                            let _data = {
                                vip_id: this.mguOnSet.vip_id,
                                name: this.mguOnSet.vip_name,
                                nickname: this.mguOnSet.vip_nickname,
                                sex: this.mguOnSet.vip_sex,
                                birth_dt: Math.floor(this.mguOnSet.vip_birth_dt / 1000),
                                mgu_id: this.mguOnSet.mgu_id,
                                medal_count: this.mguOnSet.medal_count,
                                is_trainer: this.mguOnSet.is_trainer,
                                is_adminer: this.mguOnSet.is_adminer,
                                trainer_id: this.mguOnSet.trainer_id ? this.mguOnSet.trainer_id : 0,
                                trainer_id_1st: this.mguOnSet.trainer_id_1st ? this.mguOnSet.trainer_id_1st : 0
                            }
                            this.$post("/admin/User/saveVip", _data).then((res) => {
                                if (res.rst == 0) {
                                    this.getList();
                                    this.onCloseModal();
                                }
                            }).finally(() => {
                                this.loading = false;
                            })
                        })
                    }
                })
            },
            onStatus(mgu_id, status) {
                this.$confirm(
                    "该操作将「" + (0 == status ? "删除" : "恢复") + "」所选会员，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/User/saveVip", {
                        mgu_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            onCloseModal() {
                this.showAddModal = false;
                this.showSetModal = false;
                this.mguOnSet = { ...defaultMgu };
            }
        }
    };
</script>
<style lang="less" scoped>
    .vip {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .add-btn {
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }

        /deep/.el-form-item__label {
            text-align: left;
            float: none;
            word-break: break-word;
        }
    }
</style>