let filters = {};
filters.formatPhoneno = value => {
    if (!value) return "";
    return '** ' + value.substr(-4)
};
filters.formatDt = value => {
    if (!value) return "- -";
    let time = new Date(value * 1000);
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
    let hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
    let minute =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
    let second =
        time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
    return `${year}-${month}-${day}  ${hour}:${minute}:${second}`;
};
filters.formatMidNosDt = value => {
    if (!value) return "- -";
    let time = new Date(value * 1000);
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
    let hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
    let minute =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
    return `${year}-${month}-${day}  ${hour}:${minute}`;
};
filters.formatShortDt = value => {
    if (!value) return "- -";
    let time = new Date(value * 1000);
    //let year = time.getFullYear();
    let month = time.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
    let hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
    let minute =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
    //let second = time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
    return `${month}-${day}  ${hour}:${minute}`;
};
filters.formatDate = value => {
    if (!value) {
        return ""
    }
    let time = new Date(value * 1000);
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
    return `${year}-${month}-${day}`;
};
filters.formatDateWeek = value => {
    if (!value) return "- -";
    let time = new Date(value * 1000);
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate()
    const weeks = ['日', '一', '二', '三', '四', '五', '六']
    let week = weeks[time.getDay()]
    return `${year}-${month}-${day} 周${week}`;
};
filters.formatTime = value => {
    if (!value) return "- -";
    let time = new Date(value * 1000);
    let hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
    let minute =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
    let second =
        time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
    return `${hour}:${minute}:${second}`;
};
filters.formatPrice = value => {
    return (value - 0).toFixed(2)
    // if (!value) {
    // 	return '0.00';
    // }
    // return Math.floor(value*100)/100;
}
filters.formatMoney = value => {
    let str = value.toString();
    let last_ix = str.lastIndexOf('.');
    if (last_ix < 0) {
        last_ix = str.length;
    }
    for (let ix = last_ix - 3; ix > 0;) {
        str = str.slice(0, ix) + "," + str.slice(ix);
        ix -= 3;
    }
    return str;
}
export default filters;