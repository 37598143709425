import { render, staticRenderFns } from "./Vip.vue?vue&type=template&id=e61ec5b8&scoped=true"
import script from "./Vip.vue?vue&type=script&lang=js"
export * from "./Vip.vue?vue&type=script&lang=js"
import style0 from "./Vip.vue?vue&type=style&index=0&id=e61ec5b8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e61ec5b8",
  null
  
)

export default component.exports