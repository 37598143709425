<template>
	<div class="pftd">
		<div class="toolbar mb30 f-r-s">
			<div class="operation f-r-s">
				<el-select v-model="mgu_id" size="small" filterable remote clearable placeholder="请输入会员姓名或手机号" :remote-method="remoteMethodVip" :loading="loading" @change="onSearchClick">
					<el-option v-for="item in vip_opts" :key="item.mgu_id" :label="item.txt" :value="item.mgu_id"></el-option>
				</el-select>
				<el-select v-model="trainer_mgu_id" size="small" filterable remote clearable placeholder="请输入教练姓名或手机号" :remote-method="remoteMethodTrainer" :loading="loading" style="margin-left:10px;" @change="onSearchClick">
					<el-option v-for="item in trainer_opts" :key="item.trainer_mgu_id" :label="item.txt" :value="item.trainer_mgu_id"></el-option>
				</el-select>
				<el-button slot="append" class="search-btn" type="success" size="small" style="margin-left:10px;" @click="onSearchClick">查询</el-button>
			</div>
			<div class="filters">
				<el-date-picker v-model="date_range" type="daterange" align="right" size="small" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="drOpts" @change="onSearchClick"></el-date-picker>
			</div>
		</div>

		<div class="fc_gray fs_small">注：搜索选择会员、教练后，查询相应的动态测评。</div>
		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
			<el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
			<el-table-column label="创建" width="200">
				<template slot-scope="scope">
					<div class="txtrow">时间：{{ scope.row.create_dt | formatDt }}</div>
					<div class="txtrow">会员：{{scope.row.vip.name}}<span v-if="scope.row.vip.phoneno">（{{scope.row.vip.phoneno}}）</span></div>
					<div class="txtrow">教练：{{scope.row.trainer.name}}<span v-if="scope.row.trainer.nickname|| scope.row.trainer.phoneno">（{{scope.row.trainer.nickname}} {{scope.row.trainer.phoneno | formatPhoneno}}）</span></div>
				</template>
			</el-table-column>
			<el-table-column label="过顶蹲起">
				<template slot-scope="scope">
					<div>{{scope.row.overhead_squat}}</div>
				</template>
			</el-table-column>
			<el-table-column label="单腿下蹲">
				<template slot-scope="scope">
					<div>{{scope.row.pistol_squat}}</div>
				</template>
			</el-table-column>
			<el-table-column label="站姿推">
				<template slot-scope="scope">
					<div>{{scope.row.shoulder_press}}</div>
				</template>
			</el-table-column>
			<el-table-column label="站姿拉">
				<template slot-scope="scope">
					<div>{{scope.row.pull_up}}</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
				</el-pagination>
			</div>
		</div>

	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	import {
		formatPhoneno
	} from "@/utils/formatData"
	const defaultVip = {
		mgu_id: 0,
		txt: '未选择会员'
	}
	const defaultTrainer = {
		trainer_mgu_id: 0,
		txt: '未选择教练'
	}
	export default {
        name: "survey_pftd",
		data() {
			return {
				gym_id: 0,

				vip_opts: [{
					...defaultVip
				}],
				trainer_opts: [{
					...defaultTrainer
				}],

				drOpts: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				date_range: '',

				mgu_id: 0,
				trainer_mgu_id: 0,

				loading: false,
				list: [],
				total: 0,
				page_ix: 1,
				page_size: 10
			};
		},
		computed: {
			...mapState(["user"]),
			pageStart() {
				return (this.page_ix - 1) * this.page_size;
			},
		},
		mounted() {
			this.gym_id = this.user.gym.id
			this.remoteMethodVip()
			this.remoteMethodTrainer()
			this.getList()
		},
		methods: {
			remoteMethodVip(query) {
				setTimeout(() => {
					this.loading = true;
					this.$post("/admin/Survey/remoteVips", {
						gym_id: this.gym_id,
						cdit: query
					}).then(res => {
						if (res.rst == 0) {
							this.vip_opts = [{
								...defaultVip
							}]
							res = res.data
							if (res && res.length > 0) {
								res.forEach((item, ix) => {
									this.vip_opts.push({
										mgu_id: item.id,
										txt: item.vip.name + '（' + formatPhoneno(item.vip.phoneno) + '）'
									})
								})
							}
						}
					}).finally(() => {
						this.loading = false;
					})
				}, 250)
			},
			remoteMethodTrainer(query) {
				setTimeout(() => {
					this.loading = true;
					this.$post("/admin/Survey/remoteTrainers", {
						gym_id: this.gym_id,
						cdit: query
					}).then(res => {
						if (res.rst == 0) {
							this.trainer_opts = [{
								...defaultTrainer
							}]
							res = res.data
							if (res && res.length > 0) {
								res.forEach((item, ix) => {
									this.trainer_opts.push({
										trainer_mgu_id: item.id,
										txt: item.trainer.name + '（' + item.trainer.nickname + ' ' + formatPhoneno(item.trainer.phoneno) + '）'
									})
								})
							}
						}
					}).finally(() => {
						this.loading = false;
					})
				}, 250)
			},
			getList() {
				this.loading = true;
				let _data = {
					gym_id: this.gym_id,
					mgu_id: this.mgu_id,
					trainer_mgu_id: this.trainer_mgu_id,
					page_ix: this.page_ix,
					page_size: this.page_size
				}
				if (this.date_range) {
					_data.from_dt = Math.floor(new Date(this.date_range[0]).getTime() / 1000)
					_data.to_dt = Math.floor(new Date(this.date_range[1]).getTime() / 1000) + 86399
				}
				this.$post("/admin/Survey/queryPftd", _data)
					.then(res => {
						if (res.rst == 0) {
							this.list = res.data.data;
							this.total = res.data.total;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			onSearchClick() {
				this.page_ix = 1;
				this.getList();
			},
			onPageChange(page) {
				this.page_ix = page;
				this.getList();
			}
		}
	};
</script>
<style lang="less" scoped>
	.pftd {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.add-btn {
					width: 100px;
				}
			}

			.filters {
				margin-left: 50px;
			}

			.tip {
				height: 20px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #eb5753;
			}
		}

		.form-wrap {
			.tip {
				color: #ed991f;
			}
		}
	}
</style>