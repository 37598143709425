import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import {
	apiDomain
} from '../utils/config'
import {
	Message
} from 'element-ui';

axios.defaults.withCredentials = true

export const post = async (url, params = {}) => {
	if (!url) return
	try {
		const result = await axios({
			method: 'POST',
			url: apiDomain + url,
			headers: {
				'Content-Type': 'application/json'
			},
			data: params
		});
		const res = result.data
		if (res.rst != 0 && res.msg && url.indexOf('checkJwtToken') < 0) {
			Message.error(res.msg)
		}
		if (res.rst == 0 && res.msg && url.indexOf('/save') > 0) {
			Message.success(res.msg)
		}
		return res;
	} catch (err) {
		console.log('err', err)
		// if (err.response && err.response.status && err.response.status === 403) {
		// 	store.dispatch('setCallbackUrl', window.location.hash.replace('#', ''))
		// 	window.location.href = '/#/login';
		// }
		Message.error('出错啦')
		return {};
	}
}
export const get = async (url, params = {}) => {
	if (!url) return
	let query = Object.keys(params)
	if (query.length) {
		url += '?'
		query.forEach(q => {
			url += `${q}=${params[q]}`
		})
	}
	try {
		const result = await axios({
			method: 'GET',
			url: apiDomain + url
		});
		return result.data;
	} catch (err) {
		console.log(err)
		throw err;
	}
}
window.vue = Vue
Vue.prototype.$get = get
Vue.prototype.$post = post
