<template>
    <div class="leavemsg">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-select v-model="from_user_id" size="small" filterable remote clearable placeholder="请输入会员姓名或手机号" :remote-method="remoteMethodFromuser" :loading="loading" @change="onSearchClick">
                    <el-option v-for="item in from_user_opts" :key="item.from_user_id" :label="item.txt" :value="item.from_user_id"></el-option>
                </el-select>
                <el-select v-model="to_user_id" size="small" filterable remote clearable placeholder="请输入会员姓名或手机号" :remote-method="remoteMethodTouser" :loading="loading" style="margin-left:10px;" @change="onSearchClick">
                    <el-option v-for="item in to_user_opts" :key="item.to_user_id" :label="item.txt" :value="item.to_user_id"></el-option>
                </el-select>
                <el-button slot="append" class="search-btn" type="success" size="small" style="margin-left:10px;" @click="onSearchClick">查询</el-button>
            </div>
            <div class="filters">
                <el-date-picker v-model="date_range" type="daterange" align="right" size="small" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="drOpts" @change="onSearchClick"></el-date-picker>
            </div>
        </div>

        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="时间" width="200">
                <template slot-scope="scope">
                    <div class="txtrow">{{ scope.row.create_dt | formatDt }}</div>
                    <div v-if="scope.row.audits_record && scope.row.audits_record.length" class="txtrow">
                        <div v-for="(item,ix) in scope.row.audits_record">
                            <div class="txtrow">{{ item.audit_dt | formatDt }} {{5==item.status?'审核通过':9==item.status?'拒绝':''}}</div>
                            <div v-if="item.remark" class="txtrow">{{item.remark}}</div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="留言人">
                <template slot-scope="scope">
                    <div class="txtrow">{{scope.row.from_user.name}}<span v-if="scope.row.from_user && scope.row.from_user.nickname">（{{scope.row.from_user.nickname}}）</span></div>
                    <div class="txtrow">{{scope.row.from_user.phoneno | formatPhoneno}}</div>
                </template>
            </el-table-column>
            <el-table-column label="被留言人">
                <template slot-scope="scope">
                    <div class="txtrow">{{scope.row.to_user.name}}<span v-if="scope.row.to_user && scope.row.to_user.nickname">（{{scope.row.to_user.nickname}}）</span></div>
                    <div class="txtrow">{{scope.row.to_user.phoneno | formatPhoneno}}</div>
                </template>
            </el-table-column>
            <el-table-column label="留言内容">
                <template slot-scope="scope">
                    <div>{{scope.row.ctnt}}</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
                </el-pagination>
            </div>
        </div>

    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    import {
        formatPhoneno
    } from "@/utils/formatData"
    const defaultFromuser = {
        from_user_id: 0,
        txt: '未选择留言人'
    }
    const defaultTouser = {
        to_user_id: 0,
        txt: '未选择被留言人'
    }
    export default {
        name: "survey_leavemsg",
        data() {
            return {
                gym_id: 0,

                from_user_opts: [{
                    ...defaultFromuser
                }],
                to_user_opts: [{
                    ...defaultTouser
                }],

                drOpts: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                date_range: '',

                from_user_id: 0,
                to_user_id: 0,

                cdit: "",
                loading: false,
                list: [],
                status: '',
                total: 0,
                page_ix: 1,
                page_size: 10
            };
        },
        computed: {
            ...mapState(["user"]),
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            },
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.remoteMethodFromuser()
            this.remoteMethodTouser()
            this.getList()
        },
        methods: {
            remoteMethodFromuser(query) {
                setTimeout(() => {
                    this.loading = true;
                    this.$post("/admin/Train/remoteLeavemsgUsers", {
                        gym_id: this.gym_id,
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.from_user_opts = [{ ...defaultFromuser }]
                            res = res.data
                            if (res && res.length > 0) {
                                res.forEach((item, ix) => {
                                    this.from_user_opts.push({
                                        from_user_id: item.user.id,
                                        txt: item.user.name + '（' + formatPhoneno(item.user.phoneno) + '）'
                                    })
                                })
                            }
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                }, 250)
            },
            remoteMethodTouser(query) {
                setTimeout(() => {
                    this.loading = true;
                    this.$post("/admin/Train/remoteLeavemsgUsers", {
                        gym_id: this.gym_id,
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.to_user_opts = [{ ...defaultTouser }]
                            res = res.data
                            if (res && res.length > 0) {
                                res.forEach((item, ix) => {
                                    this.to_user_opts.push({
                                        to_user_id: item.user.id,
                                        txt: item.user.name + '（' + formatPhoneno(item.user.phoneno) + '）'
                                    })
                                })
                            }
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                }, 250)
            },
            getList() {
                this.loading = true;
                let _data = {
                    gym_id: this.gym_id,
                    cdit: this.cdit,
                    status: this.status,
                    from_user_id: this.from_user_id,
                    to_user_id: this.to_user_id,
                    page_ix: this.page_ix,
                    page_size: this.page_size
                }
                if (this.date_range) {
                    _data.from_dt = Math.floor(new Date(this.date_range[0]).getTime() / 1000)
                    _data.to_dt = Math.floor(new Date(this.date_range[1]).getTime() / 1000) + 86399
                }
                this.$post("/admin/Train/queryLeavemsg", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.page_ix = 1;
                this.getList();
            },
            onPageChange(page) {
                this.page_ix = page;
                this.getList();
            }
        }
    };
</script>
<style lang="less" scoped>
    .leavemsg {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .add-btn {
                    width: 100px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }
    }
</style>