<template>
    <div class="course_course">
        <div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: space-between;">
            <!-- <div style="display: flex;align-items: center;justify-content: flex-start;">
                <el-cascader v-model="course_id" :options="courseOpts" :props="{ expandTrigger: 'hover', label:'name', value:'id', checkStrictly:true }" @change="goSet"></el-cascader>
            </div> -->
            <el-checkbox v-model="rmed_isshow" label="显示已删除" @change="getTree"></el-checkbox>
            <el-button type="success" icon="el-icon-view" circle @click="echartCourse"></el-button>
        </div>

        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="(index) => index + 1" label="序号" align="center" />
            <el-table-column v-if="thrs.length" v-for="(cc1,ccx1) in thrs" :key="ccx1" :label="cc1.name+(1==cc1.is_cate?'':'（课）')+(1==cc1.status?'':'（删）')" prop="cc1.id" align="center">
                <el-table-column v-if="cc1.children.length" v-for="(cc2,ccx2) in cc1.children" :key="ccx1+'-'+ccx2" :label="cc2.name+(1==cc2.is_cate?'':'（课）')+(1==cc2.status?'':'（删）')" align="center">
                    <template slot-scope="scope2">
                        <div v-if="scope2.row[cc2.id]" :class="0==scope2.row[cc2.id].status? 'fc_gray':''" style="cursor: pointer;" :style="{'text-decoration': 0==scope2.row[cc2.id].status? 'line-through':''}">{{scope2.row[cc2.id].name}}</div>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>

        <el-dialog :title="user.gym.name+'·课程分类及课程图'" width="1024px" :visible.sync="echarts_show" :before-close="echartsCloseModal">
            <div id="echartCourse" ref="echartCourse" style="width:960px;height:720px;"></div>
        </el-dialog>

    </div>
</template>
<script>
    import * as echarts from 'echarts';
    import {
        mapState
    } from "vuex"
    const defaultCourse = {}
    const defaultCourseOpts = [{ id: 0, name: '添加', children: [{ id: 0, name: '添加', children: [{ id: 0, name: '添加', children: [] }] }] }]
    export default {
        name: "course_overview",
        computed: {
            ...mapState(["user"])
        },
        data() {
            return {
                gym_id: 0,

                rmed_isshow: false,

                loading: false,
                tree: {},
                thrs: [],
                list: [],

                echarts: '',
                echarts_show: true,

                courseOpts: defaultCourseOpts,
                //showCourseModal: false,
                showType: 0,
                course_id: 0,
                courseOnSet: { ...defaultCourse },
                course_rule: {}
            }
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.getTree()
            this.echarts_show = false
        },
        methods: {
            getTree() {
                this.tree = {}
                this.thrs = []
                this.list = []
                this.courseOpts = []
                this.loading = true
                this.$post("/admin/Course/getGymCateCourseTree", {
                    gym_id: this.gym_id,
                    status: this.rmed_isshow ? '' : 1
                }).then((res) => {
                    if (res.rst == 0) {
                        this.tree = res.data
                        let cols = {}
                        let rowc = 0
                        if (this.tree.is_cate > 0) {
                            if (this.tree.course_count > rowc) {
                                rowc = this.tree.course_count
                            }
                            cols['0-0'] = []
                            this.tree.children.forEach((cc1, ccx1) => {
                                if (cc1.is_cate > 0) {
                                    this.thrs.push({ id: cc1.id, name: cc1.name, status: cc1.status, is_cate: cc1.is_cate, children: [] })
                                    if (cc1.course_count > rowc) {
                                        rowc = cc1.course_count
                                    }
                                    cols[cc1.id + '-0'] = []
                                    cc1.children.forEach((cc2, ccx2) => {
                                        if (cc2.is_cate > 0) {
                                            this.thrs[this.thrs.length - 1].children.push({ id: cc2.id, name: cc2.name, status: cc2.status, is_cate: cc2.is_cate })
                                            if (cc2.course_count > rowc) {
                                                rowc = cc2.course_count
                                            }
                                            cols[cc2.id] = [];
                                            cc2.children.forEach((cc3, ccx3) => {
                                                if (cc3.is_cate > 0) {

                                                } else {
                                                    cols[cc2.id].push(cc3)
                                                }
                                            })
                                        } else {
                                            cols[cc1.id + '-0'].push(cc2)
                                        }
                                    })
                                    if (cols[cc1.id + '-0'].length) {
                                        this.thrs[this.thrs.length - 1].children.push({ id: cc1.id + '-0', name: ' ', status: 1, is_cate: 1 })
                                    }
                                } else {
                                    cols['0-0'].push(cc1)
                                }
                            })
                            if (cols['0-0'].length) {
                                this.thrs.push({ id: '0-0', name: ' ', status: 1, is_cate: 1 })
                            }
                        }
                        for (let rx = 0; rx < rowc; ++rx) {
                            let row = {}
                            Object.keys(cols).forEach((cid, cix) => {
                                if (cols[cid].length > rx) {
                                    row[cid] = cols[cid][rx]
                                }
                            })
                            this.list.push(row)
                        }
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            // goSet(e) {
            //     if (e.length > 0) {
            //         this.tree.forEach((cc1, ccx1) => {
            //             if (e[0] == cc1.id) {
            //                 if (e.length > 1) {
            //                     cc1.children.forEach((cc2, ccx2) => {
            //                         if (e[1] == cc2.id) {
            //                             if (e.length > 2) {
            //                                 cc2.children.forEach((cc, ccx) => {
            //                                     if (e[2] == cc.id) {
            //                                         this.course_id = cc.id
            //                                         this.courseOnSet = cc
            //                                         return true
            //                                     }
            //                                 })
            //                             } else {
            //                                 this.course_id = cc2.id
            //                                 this.courseOnSet = cc2
            //                             }
            //                             return true
            //                         }
            //                     })
            //                 } else {
            //                     this.course_id = cc1.id
            //                     this.courseOnSet = cc1
            //                 }
            //                 return true
            //             }
            //         })
            //         this
            //     } else {
            //         this.course_id = 0
            //         this.courseOnSet = { ...defaultCourse }
            //     }
            //     this.showCourseModal = true
            //     this.showType = 2
            // },
            // courseCloseModal() {
            //     this.showCourseModal = false
            // },
            echartCourse() {
                this.echarts = echarts.init(document.getElementById('echartCourse'))
                this.echarts.showLoading();
                this.echarts_show = true
                this.echarts.setOption({
                    tooltip: {
                        trigger: 'item',
                        triggerOn: 'mousemove'
                    },
                    series: [{
                        type: 'tree',
                        data: [this.tree],
                        top: '8%',
                        right: '10%',
                        bottom: '8%',
                        left: '10%',
                        layout: 'radial',
                        symbol: 'emptyCircle',
                        symbolSize: 7,
                        initialTreeDepth: 3,
                        animationDurationUpdate: 750,
                        emphasis: {
                            focus: 'descendant'
                        }
                    }]
                })
                this.echarts.hideLoading()
            },
            echartsCloseModal() {
                this.echarts_show = false
            }
        }
    }
</script>
<style lang="less" scoped>
    /deep/.row_cell_rmed {
        color: red !important;
    }
</style>