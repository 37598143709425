<template>
    <div class="gym">
        <div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
            <div class="operation" style="display: flex;align-items: center;justify-content: flex-start;">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入场馆名称或联系电话,或连锁品牌" clearable @change="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
                </el-input>
                <!-- <el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showAddModal = true">
					新增
				</el-button> -->
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="9">停止营业</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <div class="fc_gray fs_small">注：仅管理“{{user.name}}”作为超级管理员的场馆及员工、岗位、权限。</div>

        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="(index) => index + 1 + pageStart" label="序号" />
            <el-table-column label="连锁品牌">
                <template slot-scope="scope">
                    <div class="txtrow">{{scope.row.group_sn}}</div>
                </template>
            </el-table-column>
            <el-table-column label="名称｜联系方式">
                <template slot-scope="scope">
                    <div class="txtrow">{{scope.row.name}}</div>
                    <div v-if="scope.row.tel_no || scope.row.tel_name" class="txtrow">{{scope.row.tel_no?scope.row.tel_no:''}} {{scope.row.tel_name ? scope.row.tel_name : ''}}</div>
                </template>
            </el-table-column>
            <el-table-column label="岗位与人员">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.auth_groups && Object.keys(scope.row.auth_groups).length>0" type="text" @click="echartAuth(scope.row.id,scope.row.name);">
                        <span v-for="(item,k) in scope.row.auth_groups" :key="k" style="margin-right: 5px;">
                            {{item.title}} {{item.adminers_id.length}}人
                        </span>
                    </el-button>
                    <el-button v-else type="text" @click="echartAuth(scope.row.id,scope.row.name);">管理员工、岗位、权限</el-button>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="50">
                <template slot-scope="scope">
                    <span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : 'colororange')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '停止营业')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="scope.row.status != 1" type="default" size="small" @click="goSet(scope.row)">修改
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status != 1" type="danger" size="small" @click="handleGymStatus(scope.row.id, 9)">停业
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status != 9" type="success" size="small" @click="handleGymStatus(scope.row.id, 1)">正常
                        </el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
                </el-pagination>
            </div>
        </div>

        <el-dialog class="form-wrap" :title="showAddModal ? '新增场馆' : '修改场馆'" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form @submit.native.prevent ref="gym-form" :rules="rule" :model="gymOnSet" label-position="left" style="min-width: 600px; padding: 30px 10px">
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="名称" prop="name" required>
                            <el-input v-model="gymOnSet.name" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="联系电话" prop="tel_no">
                            <el-input v-model="gymOnSet.tel_no" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="联系人" prop="tel_name">
                            <el-input v-model="gymOnSet.tel_name" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:50px;">
                    <el-col :span="22" :offset="1">
                        <el-form-item label="" style="float: right;">
                            <el-button plain @click="onCloseModal">取消</el-button>
                            <el-button type="primary" @click="onSave">保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog :title="echarts_gym_name+'·员工岗位权限分配图'" width="1280px" :visible.sync="echarts_show" :before-close="echartsCloseModal">
            <div style="position:relative;">
                <div id="echartAuth" ref="echartAuth" style="width: 1200px;height:675px;"></div>
                <div style="position: absolute;top: 0;left: 10px;">
                    <el-button-group>
                        <el-button id="btn-adminer-new" size="mini" @click="adminerNew">新增员工</el-button>
                        <el-button id="btn-group-new" size="mini" @click="groupNew">新增岗位</el-button>
                    </el-button-group>
                    <div class="fc_gray fs_small">点击员工和岗位进行编辑</div>
                </div>
            </div>
            <div class="fc_gray fs_small" style="position: relative;">
                推荐岗位：教练、前台、办公室、财务、新媒体、老板（理事）。
                <!-- <el-button size="mini" style="position: absolute;bottom: 0;right:0;" @click="adminerNew">一键配置岗位与权限（不删除已有岗位与权限）</el-button> -->
            </div>
        </el-dialog>

        <el-dialog :title="echarts_gym_name+(1==groupShowType ? '·新增岗位' : '·修改岗位')" :visible.sync="showGroupModal" :before-close="groupCloseModal">
            <el-form @submit.native.prevent ref="group-form" :rules="group_rule" :model="groupOnSet" label-position="left" style="min-width: 600px; padding: 30px 10px">
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="岗位名称：" prop="title">
                            <el-input v-model="groupOnSet.title" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="22" :offset="1">
                        <el-form-item label="权限列表：">
                            <el-button-group>
                                <el-button size="mini" @click="groupCheck(1)">全选</el-button>
                                <el-button size="mini" @click="groupCheck(0)">全不选</el-button>
                                <el-button size="mini" @click="groupCheck(-1)">反选</el-button>
                            </el-button-group>
                            <el-checkbox-group v-model="groupOnSet.rules_id" style="display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
                                <el-checkbox v-for="(value, ix) in auth_rules" :key="ix" v-if="value.id>0" :label="value.id">
                                    {{ value.title }}
                                </el-checkbox>
                                <div v-else style="height: 15px;"></div>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:50px;">
                    <el-col :span="22" :offset="1" style="display:flex;align-items: center;justify-content: space-between;">
                        <el-form-item label="">
                            <el-button type="danger" :style="{'visibility':1==groupShowType?'hidden':'inherit'}" @click="groupRemove">删除</el-button>
                        </el-form-item>
                        <el-form-item label="" style="float: right;">
                            <el-button plain @click="groupCloseModal">取消</el-button>
                            <el-button type="primary" @click="groupSave">保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

        <el-dialog :title="echarts_gym_name+(1==adminerShowType ? '·新增员工' : '·修改员工')" :visible.sync="showAdminerModal" :before-close="adminerCloseModal">
            <el-form @submit.native.prevent ref="adminer-form" :rules="adminer_rule" :model="adminerOnSet" label-position="left" style="min-width: 600px; padding: 30px 10px">
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="姓名：" prop="name">
                            <el-input v-model="adminerOnSet.name" placeholder="如果账号已存在会覆盖账号姓名" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="手机号：" prop="phoneno">
                            <el-input v-model="adminerOnSet.phoneno" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="22" :offset="1">
                        <el-form-item label="岗位列表：" prop="groups_id">
                            <el-button-group>
                                <el-button size="mini" @click="adminerCheck(1)">全选</el-button>
                                <el-button size="mini" @click="adminerCheck(0)">全不选</el-button>
                                <el-button size="mini" @click="adminerCheck(-1)">反选</el-button>
                            </el-button-group>
                            <el-checkbox-group v-model="adminerOnSet.groups_id" style="display: flex;align-items: center;justify-content: flex-start;flex-wrap: wrap;">
                                <el-checkbox v-for="(value, ix) in auth_groups" :key="ix" v-if="value.id>0" :label="value.id">
                                    {{ value.title }}
                                </el-checkbox>
                                <div v-else style="height: 15px;"></div>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:50px;">
                    <el-col :span="22" :offset="1" style="display:flex;align-items: center;justify-content: space-between;">
                        <el-form-item label="">
                            <el-button type="danger" :style="{'visibility':1==adminerShowType?'hidden':'inherit'}" @click="adminerRemove">删除</el-button>
                            <el-button :style="{'visibility':1==adminerShowType?'hidden':'inherit'}" @click="resetPW">重置登陆密码</el-button>
                        </el-form-item>
                        <el-form-item label="" style="float: right;">
                            <el-button plain @click="adminerCloseModal">取消</el-button>
                            <el-button type="primary" @click="adminerSave">保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
    import * as echarts from 'echarts';
    import {
        hex,
        hex_hmac
    } from "@/utils/hex.js"
    import { randStr } from "@/utils/formatData"
    import {
        mapState,
        mapActions
    } from 'vuex'
    const defaultGym = {}
    const defaultGroup = {
        title: '',
        rules_id: []
    }
    const defaultAdminer = {
        name: '',
        phoneno: '',
        groups_id: []
    }
    export default {
        name: "auth_gym",
        data() {
            return {
                cdit: "",
                loading: false,
                list: [],
                status: 1,
                total: 0,
                page_ix: 1,
                page_size: 10,

                gymOnSet: {
                    ...defaultGym
                },
                showAddModal: false,
                showSetModal: false,
                rule: {
                    name: [{
                        required: true,
                        message: '请输入场馆名称',
                        trigger: 'blur'
                    }],
                    tel_no: [{
                        required: true,
                        message: '请输入场馆联系电话',
                        trigger: 'blur'
                    }],
                    tel_name: [{
                        required: true,
                        message: '请输入场馆联系人',
                        trigger: 'blur'
                    }]
                },

                echarts_gym_id: 0,
                echarts_gym_name: '',
                echarts: null,
                echarts_data: '',
                echarts_show: true,
                auth_adminers: {},
                auth_groups: {},
                auth_rules: {},
                groupOnSet: {
                    ...defaultGroup
                },
                groupShowType: 0,
                group_rule: {
                    title: [{
                        required: true,
                        message: '请输入岗位标题',
                        trigger: 'blur'
                    }]
                },
                adminerOnSet: {
                    ...defaultAdminer
                },
                adminerShowType: 0,
                adminer_rule: {
                    name: [{
                        required: true,
                        message: '请输入员工姓名',
                        trigger: 'blur'
                    }],
                    phoneno: [{
                        required: true,
                        message: '请输入员工手机号',
                        trigger: 'blur'
                    }],
                    groups_id: [{
                        type: 'array',
                        required: true,
                        message: '请至少选择一项岗位',
                        trigger: 'change'
                    }]
                }
            }
        },
        computed: {
            ...mapState(["user"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            },
            showGroupModal() {
                return this.groupShowType > 0 ? true : false
            },
            showAdminerModal() {
                return this.adminerShowType > 0 ? true : false
            }
        },
        mounted() {
            this.getList()
            this.echarts_show = false
        },
        methods: {
            echartsSplitData() {
                let raw_data = {
                    nodes: [],
                    links: [],
                    categories: [{
                        name: '员工'
                    }, {
                        name: '岗位'
                    }, {
                        name: '权限'
                    }, {
                        name: '当前超管'
                    }]
                }
                let nodes_adminer_id = []
                let nodes_group_id = []
                let nodes_rule_id = []
                const adminers_length = Object.keys(this.auth_adminers).length
                const groups_length = Object.keys(this.auth_groups).length
                const rules_length = Object.keys(this.auth_rules).length
                Object.keys(this.auth_adminers).forEach((aid, aix) => {
                    aid = parseInt(aid)
                    if (nodes_adminer_id.indexOf(aid) < 0) {
                        raw_data.nodes.push({
                            id: 'adminer_' + aid,
                            name: this.auth_adminers[aid].name,
                            phoneno: this.auth_adminers[aid].phoneno,
                            symbol: 'circle',
                            symbolSize: 20,
                            x: 150,
                            y: (540 / (1 + adminers_length)) * (1 + aix),
                            value: this.auth_adminers[aid].groups_id.length + '岗位',
                            category: aid == this.user.id ? 3 : 0,
                            label: {
                                position: 'left'
                            }
                        })
                        nodes_adminer_id.push(aid)
                    }
                })
                Object.keys(this.auth_groups).forEach((gid, gix) => {
                    gid = parseInt(gid)
                    if (nodes_group_id.indexOf(gid) < 0) {
                        raw_data.nodes.push({
                            id: 'group_' + gid,
                            name: this.auth_groups[gid].title,
                            symbol: 'triangle',
                            symbolSize: 20,
                            x: 500,
                            y: (540 / (1 + groups_length)) * (1 + gix),
                            value: this.auth_groups[gid].adminers_id.length + '人',
                            category: 1,
                            label: {
                                position: 'bottom'
                            }
                        })
                        nodes_group_id.push(gid)
                    }
                })
                Object.keys(this.auth_rules).forEach((rid, rix) => {
                    rid = parseInt(rid)
                    if (nodes_rule_id.indexOf(rid) < 0) {
                        raw_data.nodes.push({
                            id: 'rule_' + rid,
                            name: this.auth_rules[rid].title,
                            symbol: 'pin',
                            symbolSize: 20,
                            x: 850,
                            y: (540 / (1 + rules_length)) * (1 + rix),
                            value: this.auth_rules[rid].name,
                            category: 2,
                            label: {
                                position: 'right'
                            }
                        })
                        nodes_rule_id.push(rid)
                    }
                })
                Object.keys(this.auth_adminers).forEach((aid, aix) => {
                    aid = parseInt(aid)
                    this.auth_adminers[aid].groups_id.forEach((gid, gix) => {
                        gid = parseInt(gid)
                        raw_data.links.push({
                            source: 'group_' + gid,
                            target: 'adminer_' + aid
                        })
                    })
                })
                Object.keys(this.auth_groups).forEach((gid, gix) => {
                    gid = parseInt(gid)
                    this.auth_groups[gid].rules_id.forEach((rid, rix) => {
                        rid = parseInt(rid)
                        raw_data.links.push({
                            source: 'group_' + gid,
                            target: 'rule_' + rid
                        })
                    })
                })
                return raw_data
            },
            echartAuth(gym_id = 0, gym_name = '') {
                this.auth_adminers = {}
                this.auth_groups = {}
                this.auth_rules = {}
                if (gym_id) {
                    this.echarts_gym_id = gym_id
                    this.echarts_gym_name = gym_name
                }
                if (this.echarts_gym_id) {
                    this.$post("/admin/Auth/getGymAuthAGRs", {
                        gym_id: this.echarts_gym_id
                    }).then((res) => {
                        if (res.rst == 0) {
                            this.auth_adminers = res.data.auth_adminers
                            this.auth_groups = res.data.auth_groups
                            this.auth_rules = res.data.auth_rules
                            this.echarts = echarts.init(document.getElementById('echartAuth'))
                            this.echarts.showLoading();
                            this.echarts_data = {
                                nodes: [],
                                links: [],
                                categories: []
                            }
                            this.echarts_data = this.echartsSplitData()
                            this.echarts.setOption({
                                color: ['#ea7ccc', '#73c0de', '#fac858', '#ee6666', '#5470c6', '#fc8452', '#9a60b4', '#3ba272'],
                                tooltip: {},
                                legend: [{
                                    data: this.echarts_data.categories.map(function(a) {
                                        return a.name;
                                    })
                                }],
                                series: [{
                                    name: '员工 岗位 权限',
                                    type: 'graph',
                                    layout: 'none',
                                    data: this.echarts_data.nodes,
                                    links: this.echarts_data.links,
                                    categories: this.echarts_data.categories,
                                    roam: true,
                                    label: {
                                        show: true,
                                        position: 'left',
                                        formatter: '{b}'
                                    },
                                    labelLayout: {
                                        hideOverlap: true
                                    },
                                    roam: 'scale',
                                    scaleLimit: {
                                        min: 1,
                                        max: 1
                                    },
                                    lineStyle: {
                                        color: 'target',
                                        width: 2,
                                        curveness: 0
                                    },
                                    emphasis: {
                                        focus: 'adjacency',
                                        lineStyle: {
                                            width: 10
                                        }
                                    }
                                }]
                            })
                            this.echarts.hideLoading()
                            this.echarts_show = true
                            const that = this
                            this.echarts.on('click', function(params) {
                                const data = params.data
                                if ('node' == params.dataType) {
                                    if (data.id.indexOf('adminer_') > -1) {
                                        const adminer_id = data.id.substring(8)
                                        that.adminerOnSet = {
                                            ...that.auth_adminers[adminer_id]
                                        }
                                        that.adminerShowType = 2
                                    } else if (data.id.indexOf('group_') > -1) {
                                        const group_id = data.id.substring(6)
                                        that.groupOnSet = {
                                            ...that.auth_groups[group_id]
                                        }
                                        that.groupShowType = 2
                                    } else;
                                } else if ('edge' == params.dataType) {

                                } else;
                            })
                        }
                    })
                }
            },
            echartsCloseModal() {
                this.list.forEach((item, ix) => {
                    if (this.echarts_gym_id == item.id) {
                        item.auth_groups = {
                            ...this.auth_groups
                        }
                    }
                })
                this.echarts_show = false
                this.echarts_gym_id = 0
            },
            groupNew() {
                this.groupOnSet = {
                    ...defaultGroup
                }
                this.groupShowType = 1
            },
            groupCheck(type = 0) {
                if (-1 == type) {
                    let ids = []
                    Object.keys(this.auth_rules).forEach((rid, rix) => {
                        rid = parseInt(rid)
                        if (this.groupOnSet.rules_id.indexOf(rid) < 0) {
                            ids.push(rid)
                        }
                    })
                    this.groupOnSet.rules_id = ids
                } else {
                    this.groupOnSet.rules_id = []
                    if (1 == type) {
                        Object.keys(this.auth_rules).forEach((rid, rix) => {
                            rid = parseInt(rid)
                            this.groupOnSet.rules_id.push(rid)
                        })
                    }
                }
            },
            groupSave() {
                this.$refs['group-form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            this.loading = true;
                            let _data = {
                                title: this.groupOnSet.title,
                                rules_id: JSON.stringify(this.groupOnSet.rules_id)
                            }
                            if (1 == this.groupShowType) {
                                _data['gym_id'] = this.echarts_gym_id
                            } else {
                                _data['group_id'] = this.groupOnSet.id
                            }
                            this.$post("/admin/Auth/saveGymGroup", _data).then((res) => {
                                if (res.rst == 0) {
                                    this.echartAuth()
                                    this.groupCloseModal()
                                }
                            }).finally(() => {
                                this.loading = false;
                            })
                        })
                    }
                })
            },
            groupRemove() {
                this.$confirm("该删除操作将不可恢复，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.loading = true;
                    this.$post("/admin/Auth/saveGymGroup", {
                        group_id: this.groupOnSet.id,
                        status: 0
                    }).then((res) => {
                        if (res.rst == 0) {
                            this.echartAuth()
                            this.groupCloseModal()
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                })
            },
            groupCloseModal() {
                this.groupShowType = 0
                this.groupOnSet = {
                    ...defaultGroup
                }
            },
            adminerNew() {
                this.adminerOnSet = {
                    ...defaultAdminer
                }
                this.adminerShowType = 1
            },
            adminerCheck(type = 0) {
                if (-1 == type) {
                    let ids = []
                    Object.keys(this.auth_groups).forEach((gid, gix) => {
                        gid = parseInt(gid)
                        if (this.adminerOnSet.groups_id.indexOf(gid) < 0) {
                            ids.push(gid)
                        }
                    })
                    this.adminerOnSet.groups_id = ids
                } else {
                    this.adminerOnSet.groups_id = []
                    if (1 == type) {
                        Object.keys(this.auth_groups).forEach((gid, gix) => {
                            gid = parseInt(gid)
                            this.adminerOnSet.groups_id.push(gid)
                        })
                    }
                }
            },
            adminerSave() {
                this.$refs['adminer-form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            this.loading = true;
                            let _data = {
                                gym_id: this.echarts_gym_id,
                                name: this.adminerOnSet.name,
                                phoneno: this.adminerOnSet.phoneno,
                                groups_id: JSON.stringify(this.adminerOnSet.groups_id)
                            }
                            if (1 == this.adminerShowType) {} else {
                                _data['adminer_id'] = this.adminerOnSet.id
                            }
                            this.$post("/admin/Auth/saveGymAdminer", _data).then((res) => {
                                if (res.rst == 0) {
                                    this.echartAuth()
                                    this.adminerCloseModal()
                                }
                            }).finally(() => {
                                this.loading = false;
                            })
                        })
                    }
                })
            },
            adminerRemove() {
                this.$confirm("该删除操作将不可恢复，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.loading = true;
                    this.$post("/admin/Auth/saveGymAdminer", {
                        gym_id: this.echarts_gym_id,
                        adminer_id: this.adminerOnSet.id,
                        status: 0
                    }).then((res) => {
                        if (res.rst == 0) {
                            this.echartAuth()
                            this.adminerCloseModal()
                        }
                    }).finally(() => {
                        this.loading = false;
                    })
                })
            },
            adminerCloseModal() {
                this.adminerShowType = 0
                this.adminerOnSet = {
                    ...defaultAdminer
                }
            },

            getList() {
                this.loading = true;
                this.$post("/admin/Auth/queryGym", {
                        user_id: this.user.id,
                        cdit: this.cdit,
                        status: this.status,
                        page_ix: this.page_ix,
                        page_size: this.page_size,
                    })
                    .then((res) => {
                        if (res.rst == 0) {
                            res = res.data
                            this.list = res.data
                            this.total = res.total
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            onSearchClick() {
                this.page_ix = 1
                this.getList()
            },
            onPageChange(page) {
                this.page_ix = page
                this.getList()
            },
            goSet(gym) {
                this.gymOnSet = {
                    ...gym
                }
                this.showSetModal = true
            },
            onSave() {
                this.$refs['gym-form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            let _data = {};
                            if (this.showModal) {
                                _data.name = this.gymOnSet.name || "";
                                _data.tel_no = this.gymOnSet.tel_no || "";
                                _data.tel_name = this.gymOnSet.tel_name || "";
                            }
                            if (this.showSetModal) {
                                _data.gym_id = this.gymOnSet.id;
                            }
                            this.$post("/admin/Auth/saveGym", _data).finally(() => {
                                this.getList();
                                this.onCloseModal();
                            });
                        });
                    }
                });
            },
            handleGymStatus(gym_id, status) {
                this.$confirm("该操作将删除所选内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/Auth/saveGym", {
                        gym_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            onCloseModal() {
                this.showAddModal = false
                this.showSetModal = false
                this.gymOnSet = {
                    ...defaultGym
                }
            },
			
			resetPW() {
			    const pw = randStr(8)
			    this.$prompt("该操作将重置登陆密码为 " + pw + "，确认记录后继续?", "提示", {
			        confirmButtonText: "确定",
			        cancelButtonText: "取消",
					inputValue:pw,
					inputPattern: /^([0-9A-Za-z_]{6,20})$/,
					inputErrorMessage: '密码由“6~20位 数字、字母、_”组成',
			        type: "info",
			    }).then(({value}) => {
			        this.$post("/admin/Auth/saveGymAdminer", {
                        gym_id: this.echarts_gym_id,
                        adminer_id: this.adminerOnSet.id,
			            password: hex_hmac(String(this.adminerOnSet.phoneno), hex(value))
			        }).finally(() => {
			            this.adminerCloseModal();
			        });
			    })
			}
        }
    };
</script>
<style lang="less">
    .gym {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 350px;
                }

                .add-btn {
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }

        #btn-adminer-new {
            background: #ea7ccc;
            border-color: #ea7ccc;
            color: white;
        }

        #btn-group-new {
            background: #73c0de;
            border-color: #73c0de;
            color: white;
        }
    }
</style>