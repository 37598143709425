<template>
	<div class="planpage">
		<div class="toolbar mb30 f-r-s">
			<div class="operation f-r-s">
				<el-select v-model="trainer_mgu_id" size="small" filterable remote placeholder="请输入教练姓名或手机号" :remote-method="remoteMethod" :loading="loading"  @change="onSearchClick">
					<el-option-group key="gym" label="">
						<el-option :key="0" label="场馆模板" :value="0"></el-option>
					</el-option-group>
					<el-option-group v-if="trainer_opts && trainer_opts.length>0" key="trainer" label="教练">
						<el-option v-for="item in trainer_opts" :key="item.trainer_mgu_id" :label="item.txt" :value="item.trainer_mgu_id"></el-option>
					</el-option-group>
				</el-select>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="status" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="0">删除</el-radio-button>
					<el-radio-button label="1">正常</el-radio-button>
				</el-radio-group>
			</div>
			<div class="filters" :style="{'display':trainer_mgu_id>0?'':'none'}">
				<el-radio-group size="small" v-model="trainer_inherit_no" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="gym">继承场馆</el-radio-button>
					<el-radio-button label="self">教练自定义</el-radio-button>
				</el-radio-group>
			</div>
		</div>

		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
			<el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
			<el-table-column label="创建">
				<template slot-scope="scope">
					<div class="txtrow">{{ scope.row.title }}</div>
					<div class="txtrow">{{ scope.row.create_dt | formatDt }}</div>
				</template>
			</el-table-column>
			<el-table-column label="宽:高">
				<template slot-scope="scope">
					<div class="txtrow">{{ scope.row.wh_ratio }}</div>
				</template>
			</el-table-column>
			<el-table-column label="模板归属">
				<template slot-scope="scope">
					<div class="txtrow">{{ scope.row.gym_id>0?'场馆模板':scope.row.trainer_mgu_id>0?(scope.row.parent_id>0?'教练模板':'教练自定义模板'):'' }}</div>
				</template>
			</el-table-column>
			<el-table-column label="状态" width="50">
				<template slot-scope="scope">
					<div class="txtrow" :class="1==scope.row.status?'fc_green':'fc_red'">{{ 1==scope.row.status ? '正常' : '删除' }}</div>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button-group>
						<el-button v-bind:disabled="0==scope.row.status||9==scope.row.status" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
						<el-button v-bind:disabled="0==scope.row.status" type="danger" size="small" @click="handlePlanpageStatus(scope.row.id, 0)">删除</el-button>
						<el-button v-bind:disabled="1==scope.row.status" type="success" size="small" @click="handlePlanpageStatus(scope.row.id, 1)">正常</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
				</el-pagination>
			</div>
		</div>

		<el-dialog class="form-wrap" :title="showAddModal ? '新增模板' : '修改模板'" :visible.sync="showModal" :before-close="onCloseModal">
			<el-form @submit.native.prevent ref="planpage-form" :rules="rule" :model="planpageOnSet" label-position="left" style="min-width: 600px; padding: 30px 10px">
				<el-row :gutter="20">
					<el-col :span="22" :offset="1">
						<div class="fc_gray fs_small">注：仅能修改模板标题，具体内容请在手机端修改。</div>
					</el-col>
					<el-col :span="12" :offset="1">
						<el-form-item label="" prop="title">
							<el-input v-model="planpageOnSet.title" />
						</el-form-item>
					</el-col>
					<el-col :span="8" :offset="2">
						<el-form-item label="" style="float: right;">
							<el-button plain @click="onCloseModal">取消</el-button>
							<el-button type="primary" @click="onSave">保存</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</el-dialog>

	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	import {
		formatPhoneno
	} from "@/utils/formatData"
	const defaultPlanpage = {}
	export default {
		name:"course_planpage",
		data() {
			return {
				gym_id: 0,

				trainer_opts: [],

				cdit: "",
				loading: false,
				list: [],
				status: 1,
				trainer_mgu_id: 0,
				trainer_inherit_no: '',
				total: 0,
				page_ix: 1,
				page_size: 10,

				planpageOnSet: {
					...defaultPlanpage
				},
				showAddModal: false,
				showSetModal: false,
				rule: {
					title: [{
						required: true,
						message: '请输入模板标题',
						trigger: ['blur', 'change']
					}]
				}
			}
		},
		computed: {
			...mapState(["user"]),
			showModal() {
				return this.showAddModal || this.showSetModal;
			},
			pageStart() {
				return (this.page_ix - 1) * this.page_size;
			},
		},
		mounted() {
			this.gym_id = this.user.gym.id
			this.remoteMethod()
			this.getList()
		},
		methods: {
			remoteMethod(query) {
				setTimeout(() => {
					this.loading = true;
					this.$post("/admin/Course/remotePlanpageTrainers", {
						gym_id: this.gym_id,
						cdit: query
					}).then(res => {
						if (res.rst == 0) {
							this.trainer_opts = []
							res = res.data
							if (res && res.length > 0) {
								res.forEach((item, ix) => {
									this.trainer_opts.push({
										trainer_mgu_id: item.id,
										txt: item.trainer.name + '（' + item.trainer.nickname + ' ' + formatPhoneno(item.trainer.phoneno) + '）'
									})
								})
							}
						}
					}).finally(() => {
						this.loading = false;
					})
				}, 250)
			},
			getList() {
				this.loading = true
				let _data = {
					gym_id: this.gym_id,
					cdit: this.cdit,
					status: this.status,
					trainer_mgu_id: this.trainer_mgu_id,
					trainer_inherit_no: this.trainer_inherit_no,
					page_ix: this.page_ix,
					page_size: this.page_size
				}
				this.$post("/admin/Course/queryPlanpage", _data)
					.then(res => {
						if (res.rst == 0) {
							this.list = res.data.data;
							this.total = res.data.total;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			onSearchClick() {
				this.page_ix = 1;
				this.getList();
			},
			onPageChange(page) {
				this.page_ix = page;
				this.getList();
			},
			goSet(planpage) {
				this.planpageOnSet = {
					...planpage
				}
				this.showSetModal = true
			},
			onSave() {
				this.$refs['planpage-form'].validate((valid) => {
					if (valid) {
						this.$confirm("该操作将保存模板内容，是否继续?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "info",
						}).then(() => {
							this.loading = true;
							let _data = {};
							if (this.showModal) {
								_data.title = this.planpageOnSet.title
							}
							if (this.showSetModal) {
								_data.planpage_id = this.planpageOnSet.id;
							}
							this.$post("/admin/Course/savePlanpage", _data).then((res) => {
								if (res.rst == 0) {
									this.getList();
									this.onCloseModal();
								}
							}).finally(() => {
								this.loading = false;
							})
						});
					}
				});
			},
			handlePlanpageStatus(planpage_id, status) {
				this.$confirm("该操作将" + (1 == status ? "恢复" : "删除") + "所选模板，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$post("/admin/Course/savePlanpage", {
						planpage_id,
						status,
					}).finally(() => {
						this.getList();
					});
				});
			},
			onCloseModal() {
				this.showAddModal = false
				this.showSetModal = false
				this.planpageOnSet = {
					...defaultPlanpage
				}
			}
		}
	};
</script>
<style lang="less" scoped>
	.planpage {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.add-btn {
					margin-left: 50px;
				}
			}

			.filters {
				margin-left: 50px;
			}
		}

		/deep/.el-form-item__label {
			text-align: left;
			float: none;
			word-break: break-word;
		}
	}
</style>