<template>
	<div class="course_cate">
		<div class="fc_orange" style="display: flex;align-items: center;justify-content: center;">
			说明：拖动左侧课程树以调整位置，或点击选中后在右侧添加、修改、删除课程内容。
		</div>
		<div style="margin-top:50px;display: flex;align-items: flex-start;justify-content: center;">
			<div style="width:300px;">
				<el-checkbox v-model="rmed_isshow" label="显示已删除" border @change="getTree"></el-checkbox>
				<el-tree :data="trees" ref="trees" node-key="id" highlight-current accordion @node-click="nodeClick" @current-change="currChange" @node-drag-start="handleDragStart" @node-drag-enter="handleDragEnter" @node-drag-leave="handleDragLeave" @node-drag-over="handleDragOver" @node-drag-end="handleDragEnd" @node-drop="handleDrop" draggable :allow-drop="allowDrop" :allow-drag="allowDrag">
					<span class="custom-tree-node" slot-scope="{ node, data }">
						<span :class="0==data.status?'fc_gray':''" :style="{'text-decoration': 0==data.status?'line-through':'none'}">{{ node.label }}</span>
						<span v-if="0>=data.is_cate" class="fs_small">（课）</span>
					</span>
				</el-tree>
			</div>
			<div style="width:25px;"></div>
			<el-form @submit.native.prevent ref="course-form" :rules="course_rule" :model="courseOnSet" style="min-width: 600px;">
				<el-row :gutter="20">
					<el-col :span="24">
						<div style="display: flex;align-items: center;justify-content: space-between;">
							<div style="display: flex;align-items: center;justify-content: flex-start;">
								<div class="fs_std fc_gray">当前位置：</div>
								<el-breadcrumb separator-class="el-icon-arrow-right">
									<el-breadcrumb-item v-for="(item,ix) in curr_nodes">{{item.label}}</el-breadcrumb-item>
								</el-breadcrumb>
							</div>
							<el-button type="warning" size="mini" :disabled="courseOnSet.id ? false : true" @click="clearCurrCourse">取消选中</el-button>
						</div>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-radio-group v-model="curr_save_cate" style="margin:10px 0 30px 0;padding:14px 15px 10px 15px;background:#fdf8e8;border:1px solid #fae8d8;border-radius: 5px;">
							<el-radio :disabled="courseOnSet.id?false:true" label="set">修改</el-radio>
							<el-radio :disabled="(!courseOnSet.id||1==courseOnSet.status)?false:true" label="top">顶部添加</el-radio>
							<el-radio :disabled="(courseOnSet.id&&1==courseOnSet.status)?false:true" label="up">上一行添加</el-radio>
							<el-radio :disabled="(courseOnSet.id &&1==courseOnSet.status&& 3>courseOnSet.level)?false:true" label="right">右侧底部添加</el-radio>
							<el-radio :disabled="(courseOnSet.id&&1==courseOnSet.status)?false:true" label="down">下一行添加</el-radio>
							<el-radio :disabled="(!courseOnSet.id||1==courseOnSet.status)?false:true" label="bottom">底部添加</el-radio>
						</el-radio-group>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="14">
						<el-form-item label="名称：" prop="name">
							<el-input v-model="courseOnSet.name" />
						</el-form-item>
					</el-col>
					<el-col :span="8" :offset="2">
						<el-form-item label="类别：">
							<el-radio-group v-model="courseOnSet.is_cate" style="width:100%;">
								<el-radio :label="1" :disabled="3==courseOnSet.level">分类</el-radio>
								<el-radio :label="0">课程</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" style="margin-top:50px;">
					<el-col :span="24" style="display:flex;align-items: center;justify-content: space-between;">
						<el-form-item label="">
							<el-button type="danger" v-if="'set'==curr_save_cate && courseOnSet.id>0" @click="handleCourseStatus">{{0==courseOnSet.status?'恢复':'删除'}}</el-button>
						</el-form-item>
						<el-form-item label="" style="float: right;">
							<el-button plain @click="clearCourse">清空</el-button>
							<el-button type="primary" @click="saveCateCourse">{{'set'==curr_save_cate ? '保存' : '新增'}}</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
	</div>
	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	const defaultCourse = {
		id: 0,
		name: '',
		is_cate: 0
	}
	export default {
		name: "course_catecourse",
		data() {
			return {
				gym_id: 0,

				rmed_isshow: false,

				trees: [],
				//defaultExpandedKeys: [],
				curr_nodes: [],
				courseOnSet: {
					...defaultCourse
				},
				nodeOnSet: '',
				curr_save_cate: 'bottom',
				course_rule: {
					name: [{
						required: true,
						message: '请输入课程分类或课程名称',
						trigger: ['blur', 'change']
					}]
				}
			}
		},
		computed: {
			...mapState(["user"])
		},
		mounted() {
			this.gym_id = this.user.gym.id
			this.getTree()
		},
		methods: {
			getTree() {
				this.trees = []
				this.$post("/admin/Course/getGymCateCourseTree", {
					gym_id: this.gym_id,
					status: this.rmed_isshow ? '' : 1
				}).then((res) => {
					if (res.rst == 0) {
						const root = res.data
						if (root.is_cate > 0) {
							this.trees = root.children
							this.trees.forEach((cc1, ccx1) => {
								if (cc1.is_cate > 0) {
									cc1.label = cc1.name
									// if (cc1.cate_count > 0) {
									// 	this.defaultExpandedKeys.push(cc1.id)
									// }
									cc1.children.forEach((cc2, ccx2) => {
										if (cc2.is_cate > 0) {
											cc2.label = cc2.name
											// if (cc2.cate_count > 0) {
											// 	this.defaultExpandedKeys.push(cc2.id)
											// }
											cc2.children.forEach((cc3, ccx3) => {
												if (cc3.is_cate > 0) {
													cc3.label = cc3.name
													// if (cc3.cate_count > 0) {
													// 	this.defaultExpandedKeys.push(cc3.id)
													// }
												} else {
													cc3.label = cc3.name
												}
											})
										} else {
											cc2.label = cc2.name
										}
									})
								} else {
									cc1.label = cc1.name
								}
							})
						}
					}
				}).finally(() => {
					this.loading = false
					this.clearCurrCourse()
				})
			},
			nodeClick(data, node, cmpt) {
				if (!this.curr_nodes.length) {
					this.curr_save_cate = 'set'
				}
				this.curr_nodes = []
				this.curr_nodes.unshift(node.data)
				if (node.parent && node.parent.data && node.parent.data.id) {
					this.curr_nodes.unshift(node.parent.data)
					if (node.parent.parent && node.parent.parent.data && node.parent.parent.data.id) {
						this.curr_nodes.unshift(node.parent.parent.data)
					}
				}
				this.curr_save_cate = 'set'
				// if (!this.curr_save_cate || !this.curr_nodes.length) {
				// 	this.curr_save_cate = 'bottom'
				// }
				// if('right'==this.curr_save_cate && 3==data.level){
				// }
				this.courseOnSet = {
					...data
				}
				this.nodeOnSet = {
					...node
				}
			},
			clearCourse() {
				this.courseOnSet.name = ''
				this.courseOnSet.is_cate = 0
			},
			clearCurrCourse() {
				this.curr_nodes = []
				this.curr_save_cate = 'bottom'
				this.courseOnSet = {
					...defaultCourse
				}
				this.nodeOnSet = ''
			},
			saveCateCourse() {
				this.$refs['course-form'].validate((valid) => {
					if (valid) {
						const save_type = 'set' == this.curr_save_cate ? (this.courseOnSet.id ? 2 : 0) : 1
						if (1 == save_type) {
							this.$confirm("该操作将新增课程" + (this.courseOnSet.is_cate > 0 ? '分类' : '') + "，是否继续?", "提示", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "info",
							}).then(() => {
								this.loading = true;
								let _data = {
									gym_id: this.gym_id,
									name: this.courseOnSet.name,
									is_cate: this.courseOnSet.is_cate,
									save_cate: this.curr_save_cate
								}
								if (this.courseOnSet.id > 0) {
									_data['base_id'] = this.courseOnSet.id
									_data['tpl_id'] = 0
									if ('top' == this.curr_save_cate || 'up' == this.curr_save_cate || 'down' == this.curr_save_cate || 'bottom' == this.curr_save_cate) {
										_data['level'] = this.courseOnSet.level
										_data['parent_id'] = this.courseOnSet.parent_id
									} else if ('right' == this.curr_save_cate) {
										_data['level'] = this.courseOnSet.level + 1
										_data['parent_id'] = this.courseOnSet.id
									}
								} else {
									_data['level'] = 1
									_data['tpl_id'] = 0
									_data['parent_id'] = 0
								}
								this.$post("/admin/Course/saveCateCourse", _data).then((res) => {
									if (res.rst == 0) {
										this.getTree()
									}
								}).finally(() => {
									this.loading = false;
								})
							})
						} else if (2 == save_type) {
							this.$confirm("该操作将修改课程" + (this.courseOnSet.is_cate > 0 ? '分类' : '') + "，是否继续?", "提示", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "info",
							}).then(() => {
								this.loading = true;
								let _data = {
									course_id: this.courseOnSet.id,
									name: this.courseOnSet.name,
									is_cate: this.courseOnSet.is_cate
								}
								this.$post("/admin/Course/saveCateCourse", _data).then((res) => {
									if (res.rst == 0) {
										this.getTree()
										// if (this.curr_nodes.length) {
										//     let new_trees = []
										//     this.trees.forEach((cc1, ccx1) => {
										//         if (this.curr_nodes[0].id == cc1.id) {
										//             if (this.curr_nodes.length > 1) {
										//                 let cc1_children = []
										//                 cc1.children.forEach((cc2, ccx2) => {
										//                     if (this.curr_nodes[1].id == cc2.id) {
										//                         if (this.curr_nodes.length > 2) {
										//                             let cc2_children = []
										//                             cc2.children.forEach((cc3, ccx3) => {
										//                                 if (this.curr_nodes[2].id == cc3.id) {
										//                                     if (3 == this.curr_nodes.length) {
										//                                         cc3.name = this.courseOnSet.name
										//                                         cc3.label = this.courseOnSet.name
										//                                         cc3.is_cate = this.courseOnSet.is_cate
										//                                     }
										//                                 }
										//                                 cc2_children.push(cc3)
										//                             })
										//                             cc2.children = cc2_children
										//                         } else {
										//                             cc2.name = this.courseOnSet.name
										//                             cc2.label = this.courseOnSet.name
										//                             cc2.is_cate = this.courseOnSet.is_cate
										//                         }
										//                     }
										//                     cc1_children.push(cc2)
										//                 })
										//                 cc1.children = cc1_children
										//             } else {
										//                 cc1.name = this.courseOnSet.name
										//                 cc1.label = this.courseOnSet.name
										//                 cc1.is_cate = this.courseOnSet.is_cate
										//             }
										//         }
										//         new_trees.push(cc1)
										//     })
										//     this.trees = new_trees
										// }
									}
								}).finally(() => {
									this.loading = false;
								})
							})
						} else {
							this.$alert("数据异常 操作失败")
						}
					}
				})
			},
			handleCourseStatus() {
				let status = (this.courseOnSet.status + 1) % 2
				this.$confirm("该操作将" + (0 == status ? '删除' : '恢复') + "课程" + (this.courseOnSet.is_cate > 0 ? '分类' : '') + "，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.loading = true;
					let _data = {
						course_id: this.courseOnSet.id,
						status: status
					}
					this.$post("/admin/Course/saveCateCourse", _data).then((res) => {
						if (res.rst == 0) {
							this.getTree()
							this.clearCurrCourse()
						}
					}).finally(() => {
						this.loading = false;
					})
				});
			},
			// removeCourse() {
			//     this.$confirm("该操作将删除课程" + (this.courseOnSet.is_cate > 0 ? '分类' : '') + "及下辖内容，是否继续?", "提示", {
			//         confirmButtonText: "确定",
			//         cancelButtonText: "取消",
			//         type: "info",
			//     }).then(() => {
			//         this.loading = true;
			//         let _data = {
			//             course_id: this.courseOnSet.id,
			//             is_cate: this.courseOnSet.is_cate,
			//             status: 0
			//         }
			//         this.$post("/admin/Course/saveCateCourse", _data).then((res) => {
			//             if (res.rst == 0) {
			//                 const parent = this.nodeOnSet.parent;
			//                 const children = parent.data.children || parent.data;
			//                 const index = children.findIndex(d => d.id === this.courseOnSet.id);
			//                 children.splice(index, 1);
			//                 this.clearCurrCourse()
			//             }
			//         }).finally(() => {
			//             this.loading = false;
			//         })
			//     })
			// },

			currChange(nodef, nodet) {
				//console.log('nodef', nodef, 'nodet', nodet)
			},
			handleDragStart(node, ev) {
				//console.log('drag start', node);
			},
			handleDragEnter(draggingNode, dropNode, ev) {
				//console.log('tree drag enter: ', dropNode.label);
			},
			handleDragLeave(draggingNode, dropNode, ev) {
				//console.log('tree drag leave: ', dropNode.label);
			},
			handleDragOver(draggingNode, dropNode, ev) {
				//console.log('tree drag over: ', dropNode.label);
			},
			handleDragEnd(draggingNode, dropNode, dropType, ev) {
				//console.log('tree drag end: ', dropNode && dropNode.label, dropType);
			},
			handleDrop(draggingNode, dropNode, dropType, ev) {
				this.loading = true;
				let _data = {
					from_course: {
						id: draggingNode.data.id,
						gym_id: draggingNode.data.gym_id,
						is_cate: draggingNode.data.is_cate,
						level: draggingNode.data.level,
						parent_id: draggingNode.data.parent_id
					},
					to_course: {
						id: dropNode.data.id,
						gym_id: dropNode.data.gym_id,
						is_cate: dropNode.data.is_cate,
						level: dropNode.data.level,
						parent_id: dropNode.data.parent_id
					},
					move_cate: dropType
				}
				this.$post("/admin/Course/moveCateCourse", _data).then((res) => {
					if (res.rst == 0) {
						this.$message({
							message: res.msg,
							type: 'success'
						});
					} else {
						this.getTree()
					}
				}).finally(() => {
					this.loading = false;
				})
			},
			allowDrag(draggingNode) {
				return true;
			},
			allowDrop(draggingNode, dropNode, type) {
				if (1 != dropNode.is_cate || 3 == dropNode.data.level) {
					return type !== 'inner';
				} else {
					return true;
				}
			}
		}
	};
</script>
<style lang="less" scoped>
</style>