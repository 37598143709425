<template>
	<div style="background: #242635;width:100%;height:100vh;display: flex;align-items: center;justify-content: center;">
		<div class="login">
			<div class="tag">
				<span>德力仕</span>
				<br />
				<span>{{year}} NEW DESIGN</span>
			</div>
			<!-- <img style="width: 480px; height: 480px;" id="printTest" src="https://hbimg.huabanimg.com/d14a537cb57f6e9450847c663d604f4bf29d5ffe1b759-hfwyGU_fw658/format/webp" alt="logo" class="logo" /> -->

			<div class="title">德力仕「健康好身材」经营管理后台</div>
			<div class="form-wrap">
				<div class="bg-shadow1"></div>
				<div class="bg-shadow2"></div>
				<div class="login-form f-c-c" style="position: relative;">
					<div class="triangle-hack"></div>
					<img id="printTest" src="https://gym.dalisfit.cn/static/logo.png?t=1" class="logo" style="width:125px;height:125px;" />
					<el-form :model="logindata" @submit.native.prevent class="form-container f-c-c" label-position="right" label-width="140px">
						<el-form-item label="手机号">
							<el-input v-on:keyup.enter.native="login" v-model="logindata.phoneno" placeholder="请输入手机号">
								<i slot="prefix" class="icon fontFamily take-icon-mobile"></i>
							</el-input>
						</el-form-item>
						<el-form-item label="密码">
							<el-input type="password" v-on:keyup.enter.native="login" v-model="logindata.password" placeholder="请输入密码">
								<i slot="prefix" class="icon fontFamily take-icon-lock"></i>
							</el-input>
						</el-form-item>
						<el-form-item label="验证码" class="verify-code">
							<el-input type="verifycode" v-on:keyup.enter.native="login" v-model="logindata.verifycode" placeholder="请输入验证码" class="verifycode-ipt">
							</el-input>
							<div class="verifycode-img">
								<img :src="verifycodeUrl" alt="">
								<div class="refresh-img" @click="verifycodeUrl = verifycodeUrl.split('time=')[0] + 'time=' + new Date().getTime()">
									看不清？换一张</div>
							</div>
						</el-form-item>
						<div class="submit-btn" @click="login">登录</div>
					</el-form>
					<div style="height: 25px;">
						<small class="colorgraylight" style="position: absolute;right: 20px;bottom:15px;">
							由 南京康迈数字科技有限公司 提供技术支持
						</small>
					</div>
				</div>
			</div>
			<div class="wave-background">
				<!-- 这里是你的内容 -->
			</div>
		</div>
	</div>
</template>
<script>
	import {
		hex,
		hex_hmac
	} from "@/utils/hex.js";
	import {
		mapActions,
		mapState
	} from 'vuex'

	export default {
        name: "login",
		data() {
			return {
				year: '',
				loading: '',
				logindata: {
					phoneno: "",
					password: "",
					verifycode: ''
				},
				verifycodeUrl: ''
			};
		},
		computed: mapState(['callbackUrl']),
		mounted() {
			// this.checkToken()
			this.year = (new Date()).getFullYear()
			this.getVerifycode()
		},
		methods: {
			...mapActions(['updateUser']),
			getVerifycode() {
				this.verifycodeUrl = `/admin/Index/getSigninVerifycode?time=${new Date().getTime()}`
			},
			login() {
				this.loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading'
				});
				let {
					phoneno,
					password,
					verifycode
				} = this.logindata;
				this.$post('/admin/Index/checkSignin', {
					phoneno,
					password: hex_hmac(String(phoneno), hex(password)),
					verifycode
				}).then(res => {
					if (res.rst == 0) {
						this.updateUser(res.data)
						this.$router.replace({
							path: this.callbackUrl || '/home'
						})
					} else {
						this.getVerifycode()
					}
				}).finally(() => {
					this.loading.close()
				})
			},
			checkToken() {
				this.loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading'
				});
				this.$post('/admin/Index/checkJwtToken', {}).then(res => {
					if (res.rst == 0) {
						this.updateUser(res.data)
						this.$router.push({
							path: this.callbackUrl || '/'
						})
					}
				}).finally(() => {
					this.loading.close()
				})
			}
		}
	}
</script>
<style lang="less">
	.login {
		//width: 100%;
		height: inherit;
		background-color: #242635;
		position: relative;
		width: 1440px;

		.tag {
			font-size: 24px;
			//font-family: FZLanTingHeiS-H-GB;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffffff;
			line-height: 36px;
			position: absolute;
			top: 107px;
			right: 95px;
		}

		.title {
			position: absolute;
			top: 100px;
			left: 10%;
			font-size: 48px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffffff;
		}

		.form-wrap {
			position: relative;
			top: 200px;
			left: 10%;
			width: 80%;

			.bg-shadow1 {
				position: absolute;
				top: 10%;
				left: 0%;
				width: 100%;
				height: 80%;
				background: #ffffff;
				opacity: 0.1;
				border-radius: 30px;
			}

			.bg-shadow2 {
				position: absolute;
				top: 5%;
				left: 5%;
				width: 90%;
				height: 85%;
				background: #ffffff;
				opacity: 0.1;
				border-radius: 30px;
			}

			.login-form {
				position: absolute;
				top: 0%;
				left: 10%;
				width: 80%;
				height: 80%;
				background: #ffffff;
				border-radius: 30px;
				overflow: hidden;
				position: relative;

				.triangle-hack {
					width: 700px;
					height: 320px;
					background-color: #D0C0A5;
					transform: rotate(-45deg);
					transform-origin: 0 320px;
					position: absolute;
					left: 0;
					top: 0;
				}

				.logo {
					width: 90px;
					height: 90px;
					position: absolute;
					top: 25px;
					left: 25px;
				}

				.form-container {
					position: relative;
					margin: 150px 25% auto 25%;

					.el-form-item {
						margin-bottom: 40px;
						width: 600px;
					}

					.el-form-item__label {
						height: 50px;
						line-height: 50px;
						font-size: 24px;
						font-family: PingFang SC;
						font-weight: 500;
						color: #222222;
						padding-right: 36px;
					}

					.el-input__inner {
						// width: 472px;
						height: 50px;
						line-height: 25px;
						border-radius: 10px;
						font-size: 18px;
					}

					input::placeholder {
						font-size: 18px;
						font-family: PingFang SC;
						font-weight: 380;
						color: #999999;
					}

					.verifycode-ipt {
						width: 250px;
						margin-right: 20px;
					}

					.verifycode-img {
						height: 50px;
						position: relative;

						img {
							height: 50px;
						}

						.refresh-img {
							position: absolute;
							bottom: -40px;
							right: 0px;
							font-size: 18px;
							font-family: PingFang SC;
							font-weight: 380;
							text-decoration: underline;
							color: #32b0c6;
							cursor: pointer;
						}
					}

					.submit-btn {
						width: 221px;
						height: 68px;
						line-height: 66px;
						background: #D0C0A5;
						border-radius: 38px;
						font-size: 38px;
						font-family: PingFang SC;
						font-weight: 480;
						color: #ffffff;
						text-align: center;
						margin: 25px auto;
						cursor: pointer;
					}
				}
			}
		}

		.verify-code {
			.el-form-item__content {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}
</style>