<template>
    <div class="notice">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-date-picker class="search-ipt" v-model="date_range" type="daterange" align="right" size="small" unlink-panels range-separator="至" start-placeholder="开始显示" end-placeholder="结束显示" :picker-options="drOpts" @change="onSearchClick"></el-date-picker>
                <el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" style="margin-left: 10px;" @click="showAddModal = true">
                    新增
                </el-button>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="is_showing" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="-1">未开始显示</el-radio-button>
                    <el-radio-button label="0">正在显示中</el-radio-button>
                    <el-radio-button label="1">显示已过期</el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <div class="fc_gray fs_small">注：手机端首页显示最近一条消息公告。</div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="创建" width="200">
                <template slot-scope="scope">
                    <div class="txtrow">{{ scope.row.create_dt | formatDt }}</div>
                    <div class="txtrow txtrow2">{{ scope.row.ctnt }}</div>
                </template>
            </el-table-column>
            <el-table-column label="显示时间">
                <template slot-scope="scope">
                    <div class="txtrow">开始：{{ scope.row.from_dt | formatDt }}</div>
                    <div class="txtrow">结束：{{ scope.row.to_dt | formatDt }}</div>
                </template>
            </el-table-column>
            <el-table-column label="可见状态">
                <template slot-scope="scope">
                    <div class="txtrow">{{1==scope.row.isfor_vip?'会员':''}}{{1==scope.row.isfor_trainer?(1==scope.row.isfor_vip?' ｜ ':'')+'教练':''}}{{1==scope.row.isfor_adminer?((1==scope.row.isfor_vip||1==scope.row.isfor_trainer)?' ｜ ':'')+'员工':''}}</div>
                    <div class="txtrow" :class="-1==scope.row.is_showing?'fc_blue':1==scope.row.is_showing?'fc_orange':'fc_green'">{{ -1==scope.row.is_showing ? '未开始显示' : 1==scope.row.is_showing ? '显示已过期' : '正在显示期' }}</div>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="80">
                <template slot-scope="scope">
                    <div class="txtrow" :class="1==scope.row.status?'fc_green':'fc_red'">{{ 1==scope.row.status ? '正常' : '删除' }}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="0==scope.row.status" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
                        <el-button v-bind:disabled="0==scope.row.status" type="danger" size="small" @click="handleNoticeStatus(scope.row.id, 0)">删除</el-button>
                        <el-button v-bind:disabled="1==scope.row.status" type="success" size="small" @click="handleNoticeStatus(scope.row.id, 1)">正常</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
                </el-pagination>
            </div>
        </div>

        <el-dialog class="form-wrap" :title="showAddModal ? '新增消息公告' : '修改消息公告'" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form @submit.native.prevent ref="notice-form" :rules="rule" :model="noticeOnSet" label-position="left" style="min-width: 600px; padding: 30px 10px">
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="公告内容" prop="ctnt">
                            <el-input v-model="noticeOnSet.ctnt" placeholder="请输入50字以内公告内容" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="起止时间" prop="dr_onset">
                            <el-date-picker v-model="noticeOnSet.dr_onset" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="drSetOpts" style="width:100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="1">
                        <el-form-item label="会员可见">
                            <el-radio-group v-model="noticeOnSet.isfor_vip">
                                <el-radio :label="1">可见</el-radio>
                                <el-radio :label="0">不可见</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="教练可见">
                            <el-radio-group v-model="noticeOnSet.isfor_trainer">
                                <el-radio :label="1">可见</el-radio>
                                <el-radio :label="0">不可见</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="员工可见">
                            <el-radio-group v-model="noticeOnSet.isfor_adminer">
                                <el-radio :label="1">可见</el-radio>
                                <el-radio :label="0">不可见</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:50px;">
                    <el-col :span="22" :offset="1">
                        <el-form-item label="" style="float: right;">
                            <el-button plain @click="onCloseModal">取消</el-button>
                            <el-button type="primary" @click="onSave">保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    let today_dt = new Date()
    today_dt.setHours(0, 0, 0, 0)
    today_dt = today_dt.getTime()
    const defaultNotice = {
        ctnt: '',
        isfor_vip: 1,
        isfor_trainer: 1,
        isfor_adminer: 1,
        dr_onset: [today_dt, today_dt + 1000 * (2 * 86400 - 1)]
    }
    export default {
		name:"globalset_notice",
        data() {
            return {
                gym_id: 0,

                drOpts: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                date_range: '',

                loading: false,
                list: [],
                status: 1,
                is_showing: 0,
                total: 0,
                page_ix: 1,
                page_size: 10,

                noticeOnSet: { ...defaultNotice },
                drSetOpts: {
                    shortcuts: [{
                        text: '未来一天',
                        onClick(picker) {
                            picker.$emit('pick', [today_dt, today_dt + 1000 * (2 * 86400 - 1)])
                        }
                    }, {
                        text: '未来一周',
                        onClick(picker) {
                            picker.$emit('pick', [today_dt, today_dt + 1000 * (7 * 86400 - 1)])
                        }
                    }, {
                        text: '未来一个月',
                        onClick(picker) {
                            picker.$emit('pick', [today_dt, today_dt + 1000 * (30 * 86400 - 1)]);
                        }
                    }, {
                        text: '未来三个月',
                        onClick(picker) {
                            picker.$emit('pick', [today_dt, today_dt + 1000 * (90 * 86400 - 1)]);
                        }
                    }]
                },
                dr_onset: [],
                showAddModal: false,
                showSetModal: false,
                rule: {
                    ctnt: [{
                        required: true,
                        message: '请输入4～50字的公告内容',
                        trigger: ['blur', 'change']
                    }, {
                        validator: (rule, value, callback) => {
                            if (value.length > 3 && value.length < 51) {
                                callback();
                            } else {
                                callback(new Error('请输入4～50字的公告内容'));
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    dr_onset: [{
                        required: true,
                        message: '请选择合适的公告显示时间',
                        trigger: ['blur', 'change']
                    }, {
                        validator: (rule, value, callback) => {
                            if (!value || 2 != value.length || !value[0] || !value[1] || value[0] > value[1]) {
                                callback(new Error('请选择合适的公告显示时间'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    isfor_vip: [{
                        validator: (rule, value, callback) => {
                            if (0 != value && 1 != value) {
                                callback(new Error('请选择是否会员可见'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    isfor_trainer: [{
                        validator: (rule, value, callback) => {
                            if (0 != value && 1 != value) {
                                callback(new Error('请选择是否教练可见'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }],
                    isfor_adminer: [{
                        validator: (rule, value, callback) => {
                            if (0 != value && 1 != value) {
                                callback(new Error('请选择是否员工可见'));
                            } else {
                                callback();
                            }
                        },
                        trigger: ['blur', 'change']
                    }]
                }
            }
        },
        computed: {
            ...mapState(["user"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            },
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                let _data = {
                    gym_id: this.gym_id,
                    status: this.status,
                    is_showing: this.is_showing,
                    page_ix: this.page_ix,
                    page_size: this.page_size
                }
                if (this.date_range) {
                    _data.from_dt = Math.floor(new Date(this.date_range[0]).getTime() / 1000)
                    _data.to_dt = Math.floor(new Date(this.date_range[1]).getTime() / 1000) + 86399
                }
                this.$post("/admin/Ctrlboard/queryNotice", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.page_ix = 1;
                this.getList();
            },
            onPageChange(page) {
                this.page_ix = page;
                this.getList();
            },
            goSet(notice) {
                this.noticeOnSet = {
                    ...notice,
                    dr_onset: [1000 * notice.from_dt, 1000 * notice.to_dt]
                }
                this.showSetModal = true
            },
            onSave() {
                this.$refs['notice-form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("该操作将保存消息公告，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            let _data = {
                                gym_id: this.gym_id,
                                isfor_vip: this.noticeOnSet.isfor_vip,
                                isfor_trainer: this.noticeOnSet.isfor_trainer,
                                isfor_adminer: this.noticeOnSet.isfor_adminer,
                                from_dt: Math.floor(this.noticeOnSet.dr_onset[0] / 1000),
                                to_dt: Math.floor(this.noticeOnSet.dr_onset[1] / 1000),
                                ctnt: this.noticeOnSet.ctnt
                            }
                            if (this.showSetModal) {
                                _data.notice_id = this.noticeOnSet.id;
                            }
                            this.$post("/admin/Ctrlboard/saveNotice", _data).then((res) => {
                                if (res.rst == 0) {
                                    this.getList();
                                    this.onCloseModal();
                                }
                            }).finally(() => {
                                this.loading = false;
                            })
                        });
                    }
                });
            },
            handleNoticeStatus(notice_id, status) {
                this.$confirm("该操作将" + (1 == status ? "恢复" : (0 == status ? "删除" : "")) + "所选消息公告，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/Ctrlboard/saveNotice", {
                        notice_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            onCloseModal() {
                this.showAddModal = false;
                this.showSetModal = false;
                this.noticeOnSet = { ...defaultNotice };
            }
        }
    };
</script>
<style lang="less" scoped>
    .notice {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .el-dialog {
            min-width: 660px;
        }
    }
</style>