<template>
    <div class="logauth">
        <div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
            <div class="operation" style="display: flex;align-items: center;justify-content: flex-start;">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入操作人员手机号或姓名" clearable @change="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="type" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="1">登陆场馆</el-radio-button>
                    <el-radio-button label="2">切换场馆</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-date-picker v-model="date_range" type="daterange" align="right" size="small" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="drOpts" @change="onSearchClick"></el-date-picker>
            </div>
        </div>

        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="(index) => index + 1 + pageStart" label="序号" />
            <el-table-column label="时间">
                <template slot-scope="scope">
                    <div class="txtrow">{{scope.row.create_dt|formatDt}}</div>
                </template>
            </el-table-column>
            <el-table-column label="超管｜员工">
                <template slot-scope="scope">
                    <div class="txtrow">{{scope.row.user.name}}</div>
                    <div class="txtrow">{{scope.row.user.phoneno}}</div>
                </template>
            </el-table-column>
            <el-table-column label="场馆">
                <template slot-scope="scope">
                    <div v-if="scope.row.gym.group_sn" class="txtrow">品牌：{{scope.row.gym.group_sn}}</div>
                    <div class="txtrow">{{scope.row.gym.name}}</div>
                    <div class="txtrow">{{scope.row.gym.tel_no}}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <div class="txtrow">{{scope.row.type_txt}}</div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
                </el-pagination>
            </div>
        </div>


    </div>
</template>
<script>
    import {
        mapState
    } from 'vuex'
    export default {
		name:"audit_logauth",
        data() {
            return {
                gym_id: 0,

                drOpts: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                date_range: '',

                cdit: '',
                loading: false,
                list: [],
                type: '',
                total: 0,
                page_ix: 1,
                page_size: 10
            }
        },
        computed: {
            ...mapState(["user"]),
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            }
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                let _data = {
                    gym_id: this.gym_id,
                    cdit: this.cdit,
                    type: this.type,
                    page_ix: this.page_ix,
                    page_size: this.page_size
                }
                if (this.date_range) {
                    _data.from_dt = Math.floor(new Date(this.date_range[0]).getTime() / 1000)
                    _data.to_dt = Math.floor(new Date(this.date_range[1]).getTime() / 1000) + 86399
                }
                this.$post("/admin/Audit/queryLogauth", _data)
                    .then((res) => {
                        if (res.rst == 0) {
                            res = res.data
                            this.list = res.data
                            this.list.forEach((item, ix) => {
                                switch (item.type) {
                                    case 1:
                                        item.type_txt = '登陆场馆';
                                        break;
                                    case 2:
                                        item.type_txt = '切换场馆';
                                        break;
                                    default:
                                        item.type_txt = '未知';
                                        break;
                                }
                            })
                            this.total = res.total
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            onSearchClick() {
                this.page_ix = 1
                this.getList()
            },
            onPageChange(page) {
                this.page_ix = page
                this.getList()
            }
        }
    };
</script>
<style lang="less">
    .logauth {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .add-btn {
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }

        #btn-adminer-new {
            background: #ea7ccc;
            border-color: #ea7ccc;
            color: white;
        }

        #btn-group-new {
            background: #73c0de;
            border-color: #73c0de;
            color: white;
        }
    }
</style>