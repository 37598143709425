<template>
    <div class="trainer">
        <div class="toolbar" style="margin-bottom:20px;display:flex;align-items: center;justify-content: flex-start;">
            <div class="operation" style="display: flex;align-items: center;justify-content: flex-start;">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入教练手机号或姓名" clearable @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" @click="onSearchClick">查询</el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-checkbox v-model="only_trainer" @change="onSearchClick">仅教练（不显示员工、会员）</el-checkbox>
            </div>
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="头像" width="80">
                <template slot-scope="scope">
                    <el-image fit="contain" :src="scope.row.trainer.avatar_url" style="width: 60px;height: 60px;border-radius: 5px;"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="账号信息">
                <template slot-scope="scope">
                    <div>姓名：{{ scope.row.trainer.name }}（{{scope.row.trainer.nickname}} {{ scope.row.trainer.sex == 1 ? "男" : "女" }}）</div>
                    <div>手机：{{ scope.row.trainer.phoneno }}</div>
                </template>
            </el-table-column>
            <el-table-column label="加入场馆">
                <template slot-scope="scope">
                    <div>加入时间：{{scope.row.create_dt | formatDate}}</div>
                    <div>是否员工：{{1==scope.row.is_adminer?'是':'否'}}</div>
                </template>
            </el-table-column>
            <el-table-column label="状态" width="50">
                <template slot-scope="scope">
                    <span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="0==scope.row.status" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
                        <el-button v-bind:disabled="0==scope.row.status" type="danger" size="small" @click="onStatus(scope.row.id, 0)">删除</el-button>
                        <el-button v-bind:disabled="1==scope.row.status" type="success" size="small" @click="onStatus(scope.row.id, 1)">正常</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar" style="display: flex;align-items: center;justify-content: flex-start;">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="page_size" v-if="total > page_size" @current-change="onPageChange" :current-page.sync="page_ix">
                </el-pagination>
            </div>
        </div>

        <el-dialog class="form-wrap" :title="showAddModal ? '新增教练' : '修改教练'" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form class="form-wrap" @submit.native.prevent ref="user-form" :rules="rule" :model="mguOnSet" label-position="left" style="padding: 30px 10px">
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="手机号" prop="trainer_phoneno">
                            <el-input v-model="mguOnSet.trainer_phoneno" :disabled="showSetModal" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="10" :offset="1">
                        <el-form-item label="姓名" prop="trainer_name">
                            <el-input v-model="mguOnSet.trainer_name" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="10" :offset="2">
                        <el-form-item label="昵称/英文名">
                            <el-input v-model="mguOnSet.trainer_nickname" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="1">
                        <el-form-item label="性别">
                            <el-radio-group v-model="mguOnSet.trainer_sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="0">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="是否教练">
                            <el-radio-group v-model="mguOnSet.is_trainer">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" :offset="2">
                        <el-form-item label="是否员工">
                            <el-radio-group v-model="mguOnSet.is_adminer">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" :offset="1">
                        <el-form-item label="出生生日" prop="birth_dt">
                            <el-date-picker v-model="mguOnSet.trainer_birth_dt" value-format="timestamp" type="date" placeholder="选择日期" style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" style="margin-top:50px;">
                    <el-col :span="22" :offset="1" style="display:flex;align-items: center;justify-content: space-between;">
                        <el-form-item label="">
                            <el-button plain @click="initPassword">重置密码</el-button>
                            <el-button plain @click="initPayPassword">重置支付密码</el-button>
                        </el-form-item>
                        <el-form-item label="" style="float: right;">
                            <el-button plain @click="onCloseModal">取消</el-button>
                            <el-button type="primary" @click="onSave">保存设置</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
    import {
        hex,
        hex_hmac
    } from "@/utils/hex.js"
    import {
        mapState
    } from "vuex"
    import { randStr } from "@/utils/formatData"
    const defaultMgu = {}
    export default {
        name: "user_trainer",
        computed: {
            ...mapState(["user"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.page_ix - 1) * this.page_size;
            },
        },
        data() {
            return {
                gym_id: 0,

                only_trainer: false,

                cdit: "",
                loading: false,
                list: [],
                status: "1",
                total: 0,
                page_ix: 1,
                page_size: 10,

                mguOnSet: { ...defaultMgu },
                showAddModal: false,
                showSetModal: false,
                rule: {
                    trainer_name: [{
                        required: true,
                        message: '请输入教练姓名',
                        trigger: 'blur'
                    }],
                    trainer_phoneno: [{
                        required: true,
                        message: '请输入教练手机号',
                        trigger: 'blur'
                    }]
                }
            };
        },
        mounted() {
            this.gym_id = this.user.gym.id
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                let _data = {
                    gym_id: this.gym_id,
                    cdit: this.cdit,
                    status: this.status,
                    page_ix: this.page_ix,
                    page_size: this.page_size
                }
                if (this.only_trainer) {
                    _data['is_adminer'] = 0
                }
                this.$post("/admin/User/queryTrainer", _data).then(res => {
                    if (res.rst == 0) {
                        this.list = res.data.data;
                        this.total = res.data.total;
                    }
                }).finally(() => {
                    this.loading = false;
                })
            },
            onSearchClick() {
                this.page_ix = 1;
                this.getList();
            },
            onPageChange(page) {
                this.page_ix = page;
                this.getList();
            },
            goSet(mgu) {
                this.mguOnSet = {
                    trainer_id: mgu.trainer.id,
                    trainer_phoneno: mgu.trainer.phoneno,
                    trainer_wechat_openid: mgu.trainer.wechat_openid,
                    trainer_name: mgu.trainer.name,
                    trainer_nickname: mgu.trainer.nickname,
                    trainer_sex: mgu.trainer.sex,
                    trainer_birth_dt: mgu.trainer.birth_dt ? 1000 * mgu.trainer.birth_dt : '',
                    trainer_password: '',
                    trainer_pay_password: '',
                    mgu_id: mgu.id,
                    is_trainer: mgu.is_trainer,
                    is_adminer: mgu.is_adminer
                }
                this.showSetModal = true;
            },
            initPassword() {
                const pw = randStr(8)
                this.$confirm("该操作将重置登陆密码为 " + pw + "，确认记录后继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/User/saveTrainer", {
                        trainer_id: this.mguOnSet.trainer_id,
                        password: hex_hmac(String(this.mguOnSet.trainer_wechat_openid), hex(pw))
                    }).finally(() => {
                        this.onCloseModal();
                    });
                })
            },
            initPayPassword() {
                const pw = randStr(8)
                this.$confirm("该操作将重置支付密码为 " + pw + "，确认记录后继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/User/saveTrainer", {
                        trainer_id: this.mguOnSet.trainer_id,
                        pay_password: hex_hmac(String(this.mguOnSet.trainer_wechat_openid), hex(pw))
                    }).finally(() => {
                        this.onCloseModal();
                    });
                })
            },
            onSave() {
                this.$refs['user-form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("该操作将修改教练信息，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            this.loading = true;
                            let _data = {
                                trainer_id: this.mguOnSet.trainer_id,
                                name: this.mguOnSet.trainer_name,
                                nickname: this.mguOnSet.trainer_nickname,
                                sex: this.mguOnSet.trainer_sex,
                                birth_dt: Math.floor(this.mguOnSet.trainer_birth_dt / 1000),
                                mgu_id: this.mguOnSet.mgu_id,
                                is_trainer: this.mguOnSet.is_trainer,
                                is_adminer: this.mguOnSet.is_adminer
                            }
                            this.$post("/admin/User/saveTrainer", _data).then((res) => {
                                if (res.rst == 0) {
                                    this.getList();
                                    this.onCloseModal();
                                }
                            }).finally(() => {
                                this.loading = false;
                            })
                        })
                    }
                })
            },
            onStatus(mgu_id, status) {
                this.$confirm(
                    "该操作将「" + (0 == status ? "删除" : "恢复") + "」所选教练，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/User/saveTrainer", {
                        mgu_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            onCloseModal() {
                this.showAddModal = false;
                this.showSetModal = false;
                this.mguOnSet = { ...defaultMgu };
            }
        }
    };
</script>
<style lang="less" scoped>
    .trainer {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .add-btn {
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }

        /deep/.el-form-item__label {
            text-align: left;
            float: none;
            word-break: break-word;
        }
    }
</style>