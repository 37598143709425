let util = {};
util.setStore = (name, content) => {
    if (!name) return
    localStorage.setItem(name, JSON.stringify(content))
}
util.getStore = name => {
    if (!name) return
    let result = JSON.parse(localStorage.getItem(name))
    return result
}
util.removeStore = name => {
    if (!name) return
    localStorage.removeItem(name)
}
util.clearStore = () => {
    localStorage.clear()
}
export default util