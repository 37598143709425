<template ref="cpt_aside">
    <div class="mynav" style="position: relative;">
        <div style="margin:20px 10px;display:flex;align-items: center;justify-content: center;">
            <div v-if="isCollapse" style="display: flex;align-items: center;justify-content: center;">
                <div class="fs_large fc_light el-icon-s-unfold" @click="isCollapse=!isCollapse"></div>
            </div>
            <div v-else style="width: 180px;height:60px;display: block;">
                <div class="fs_large fc_light el-icon-s-fold" style="" @click="isCollapse=!isCollapse"></div>
                <div class="fs_huge fc_white txtrow" style="">{{user.gym.name}}</div>
            </div>
        </div>

        <el-menu :default-active="active" router class="nav" :collapse="isCollapse" :unique-opened="true" background-color="#212D3C" text-color="#C2CCD9" active-text-color="#FBBC04">
            <div v-for="(n, index) in nav" :key="n.title">
                <el-submenu :index="String(index)" v-if="isSubmenuShow(n.rules)">
                    <template slot="title">
                        <i v-if="n.icon" :class="n.icon"></i>
                        <img v-if="n.img" :src="n.img" />
                        <span slot="title">{{ n.title }}</span>
                    </template>
                    <el-menu-item v-if="isMenuShow(sub.route)" v-for="sub in n.children" :key="sub.id" :index="sub.route">
                        <span>{{ sub.title }}</span>
                    </el-menu-item>
                </el-submenu>
            </div>
        </el-menu>
    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    import {
        EventBus
    } from '../../eventBus.js'
    export default {
        name: "webaside",
        // props: [
        //     "isCollapse"
        // ],
        data() {
            return {
                isCollapse: false,
                user_gymed_rules_name: []
            }
        },
        computed: {
            ...mapState(["nav", "user"]),
            active() {
                return this.$route.path;
            },
        },
        created() {
            this.updateGymedRules()
            EventBus.$on('updateGymedRules', (data) => {
                this.updateGymedRules()
            })
        },
        methods: {
            updateGymedRules() {
                this.user_gymed_rules_name = []
                if (this.user.gym && this.user.gym.rules && this.user.gym.rules.length) {
                    this.user.gym.rules.forEach((rule, rix) => {
                        this.user_gymed_rules_name.push(rule.name)
                    })
                }
            },
            isSubmenuShow(arr) {
                if (arr.some(item => !item.includes('root_'))) {
                    if (arr.some(item => !item.includes('auth_'))) {
                        if (this.user.gym.is_super) {
                            return true
                        } else if (this.user_gymed_rules_name && this.user_gymed_rules_name.length) {
                            return arr.some(item => this.user_gymed_rules_name.includes(item))
                        } else {
                            return false
                        }
                    } else {
                        return (1 == this.user.is_root || 1 == this.user.gym.is_super) ? true : false
                    }
                } else {
                    return 1 == this.user.is_root ? true : false
                }
            },
            isMenuShow(c) {
                if ('/root_' !== c.substr(0, 6)) {
                    if ('/auth_' !== c.substr(0, 6)) {
                        if (this.user.gym.is_super) {
                            return true;
                        } else if (this.user_gymed_rules_name && this.user_gymed_rules_name.length) {
                            return this.user_gymed_rules_name.includes(c.replace(/\//g, ''))
                        } else {
                            return false;
                        }
                    } else {
                        return (1 == this.user.is_root || 1 == this.user.gym.is_super) ? true : false
                    }
                } else {
                    return 1 == this.user.is_root ? true : false
                }
            }
        }
    }
</script>
<style lang="scss">
    .mynav {
        .nav:not(.el-menu--collapse) {
            width: 200px;
            min-height: 400px;
        }

        li {
            text-align: left;
        }

        .el-menu {
            border: 0;
        }

        .el-submenu__title i {
            color: #ffffff;
        }

        .el-submenu__title img {
            width: 16px;
            margin-left: 3px;
            margin-right: 8px;
        }

        .el-submenu__title {
            background-color: #334154 !important;
            color: #f0f0f0;
        }

        .el-submenu .el-menu .el-menu-item {
            background-color: #212d3c !important;
        }

        .el-menu--collapse {
            .el-submenu__title {
                span {
                    display: none;
                }

                .el-submenu__icon-arrow {
                    display: none;
                }
            }
        }
    }
</style>