export function randStr(length = 8) {
	const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	let result = '';
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		result += characters[randomIndex];
	}
	return result;
}


export function daysToTxt(days_type, days_dt) {
	let days_dt_txt = '';
	if (1 == days_type) {
		days_dt_txt = '单天 '
		if (days_dt[0]) {
			const date = new Date(days_dt[0] * 1000)
			days_dt_txt += (date.getMonth() + 1) + '月' + date.getDate() + '日'
		}
	} else if (2 == days_type) {
		days_dt_txt = '范围 '
		let date = ''
		if (days_dt[0]) {
			date = new Date(days_dt[0] * 1000)
			days_dt_txt += (date.getMonth() + 1) + '月' + date.getDate() + '日~'
		}
		if (days_dt[1]) {
			date = new Date(days_dt[1] * 1000)
			days_dt_txt += (days_dt_txt ? '' : '~') + (date.getMonth() + 1) + '月' + date.getDate() + '日'
		}
	} else if (3 == days_type) {
		days_dt_txt = '离散 '
		let date = ''
		let last_month = '';
		let month = '';
		days_dt.forEach((item, ix) => {
			date = new Date(item * 1000)
			month = (date.getMonth() + 1) + '月'
			if (last_month != month) {
				days_dt_txt += month
				last_month = month
			}
			days_dt_txt += date.getDate() + '日、'
		})
		days_dt_txt = days_dt_txt.substring(0, days_dt_txt.length - 1)
	} else;
	return days_dt_txt
}

export function formatPhoneno(value) {
	if (!value)
		return "- -";
	return '** '+value.substr(-4);
}

export default {
	formatDt(value) {
		if (!value) return "- -";
		let time = new Date(value * 1000);
		let year = time.getFullYear();
		let month = time.getMonth() + 1;
		if (month < 10) {
			month = "0" + month;
		}
		let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
		let hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
		let minute =
			time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
		let second =
			time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
		return `${year}-${month}-${day}  ${hour}:${minute}:${second}`;
	}
}
// 2022.11.02 ym
export function formatDate(value) {
	if (!value) return "- -";
	let time = new Date(value * 1000);
	let year = time.getFullYear();
	let month = time.getMonth() + 1;
	if (month < 10) {
		month = "0" + month;
	}
	let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
	return `${year}-${month}-${day}`;
}
export function formatDateMonth(value) {
	if (!value) return "- -";
	let time = new Date(value * 1000);
	let year = time.getFullYear();
	let month = time.getMonth() + 1;
	if (month < 10) {
		month = "0" + month;
	}
	return `${year}-${month}`;
}
export function formatMoney(value) {
	let str = value.toString();
	let last_ix = str.lastIndexOf('.');
	if (last_ix < 0) {
		last_ix = str.length;
	}
	for (let ix = last_ix - 3; ix > 0;) {
		str = str.slice(0, ix) + "," + str.slice(ix);
		ix -= 3;
	}
	return str;
}