<template>
	<div class="effect">
        健康筛查 尽情期待
	</div>
</template>
<script>
	export default {
		name: "survey_hs"
	}
</script>
<style lang="less" scoped>
</style>