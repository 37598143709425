import Vue from 'vue'
import {
    Message
} from 'element-ui';
import VueRouter from 'vue-router'
import store from './store'

import Login from './views/Login.vue'
import Layout from './views/Layout.vue'
import Home from './views/Home.vue'
import GlobalsetProtocol from './views/globalset/Protocol.vue'
import GlobalsetNotice from './views/globalset/Notice.vue'
import UserVip from './views/user/Vip.vue'
import UserTrainer from './views/user/Trainer.vue'
import UserTrainerachieve from './views/user/Trainerachieve.vue'
import UserAdminer from './views/user/Adminer.vue'
import SurveyFpa from './views/survey/Fpa.vue'
import SurveyFaq from './views/survey/Faq.vue'
import SurveyBm from './views/survey/Bm.vue'
import SurveyHs from './views/survey/Hs.vue'
import SurveyPft from './views/survey/Pft.vue'
import SurveyPfts from './views/survey/Pfts.vue'
import SurveyPftd from './views/survey/Pftd.vue'
import SurveyFms from './views/survey/Fms.vue'
import SurveyMore from './views/survey/More.vue'
import CourseCatecourse from './views/course/Catecourse.vue'
import CourseOverview from './views/course/Overview.vue'
import CoursePlanpage from './views/course/Planpage.vue'
import CoursePlan from './views/course/Plan.vue'
import CourseCard from './views/course/Card.vue'
import OrderContract from './views/order/Contract.vue'
import TrainEffect from './views/train/Effect.vue'
import TrainViphandup from './views/train/Viphandup.vue'
import TrainLeavemsg from './views/train/Leavemsg.vue'
import AuditLogauth from './views/audit/Logauth.vue'
import AuthGym from './views/auth/Gym.vue'
import AuthRule from './views/auth/Rule.vue'
import RootGym from './views/root/Gym.vue'

const {
    user,
    routesMap
} = store.state

const mainPages = [{
    path: '/home',
    title: '首页',
    component: Home,
    name: Home.name
}, {
    path: '/globalset_protocol',
    title: '协议文本',
    component: GlobalsetProtocol,
    name: GlobalsetProtocol.name
}, {
    path: '/globalset_notice',
    title: '消息公告',
    component: GlobalsetNotice,
    name: GlobalsetNotice.name
}, {
    path: '/user_vip',
    title: '会员列表',
    component: UserVip,
    name: UserVip.name
}, {
    path: '/user_trainer',
    title: '教练列表',
    component: UserTrainer,
    name: UserTrainer.name
},  {
    path: '/user_trainerachieve',
    title: '教练业绩',
    component: UserTrainerachieve,
    name: UserTrainerachieve.name
}, {
    path: '/user_adminer',
    title: '员工列表',
    component: UserAdminer,
    name: UserAdminer.name
}, {
    path: '/survey_fpa',
    title: '性格色彩',
    component: SurveyFpa,
    name: SurveyFpa.name
}, {
    path: '/survey_faq',
    title: '问卷调查',
    component: SurveyFaq,
    name: SurveyFaq.name
}, {
    path: '/survey_bm',
    title: '身体测评',
    component: SurveyBm,
    name: SurveyBm.name
}, {
    path: '/survey_hs',
    title: '健康筛查',
    component: SurveyHs,
    name: SurveyHs.name
}, {
    path: '/survey_pft',
    title: '体适能测评',
    component: SurveyPft,
    name: SurveyPft.name
}, {
    path: '/survey_pfts',
    title: '静态测评',
    component: SurveyPfts,
    name: SurveyPfts.name
}, {
    path: '/survey_pftd',
    title: '动态测评',
    component: SurveyPftd,
    name: SurveyPftd.name
}, {
    path: '/survey_fms',
    title: 'FMS测评',
    component: SurveyFms,
    name: SurveyFms.name
}, {
    path: '/survey_more',
    title: '自定义测评',
    component: SurveyMore,
    name: SurveyMore.name
}, {
    path: '/course_catecourse',
    title: '分类课程',
    component: CourseCatecourse,
    name: CourseCatecourse.name
}, {
    path: '/course_overview',
    title: '课程总览',
    component: CourseOverview,
    name: CourseOverview.name
}, {
    path: '/course_planpage',
    title: '规划模板',
    component: CoursePlanpage,
    name: CoursePlanpage.name
}, {
    path: '/course_plan',
    title: '训练规划',
    component: CoursePlan,
    name: CoursePlan.name
}, {
    path: '/course_card',
    title: '健身卡',
    component: CourseCard,
    name: CourseCard.name
}, {
    path: '/order_contract',
    title: '合同列表',
    component: OrderContract,
    name: OrderContract.name
}, {
    path: '/train_effect',
    title: '训练效果',
    component: TrainEffect,
    name: TrainEffect.name
},  {
    path: '/train_viphandup',
    title: '会员上报',
    component: TrainViphandup,
    name: TrainViphandup.name
},  {
    path: '/train_leavemsg',
    title: '会员留言',
    component: TrainLeavemsg,
    name: TrainLeavemsg.name
}, {
    path: '/audit_logauth',
    title: 'PC后台日志',
    component: AuditLogauth,
    name: AuditLogauth.name
}, {
    path: '/auth_gym',
    title: '场馆管理',
    component: AuthGym,
    name: AuthGym.name
}, {
    path: '/auth_rule',
    title: '权限列表',
    component: AuthRule,
    name: AuthRule.name
}, {
    path: '/root_gym',
    title: '场馆登记',
    component: RootGym,
    name: RootGym.name
}]

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'layout',
        component: Layout,
        redirect: '/home',
        beforeEnter: (to, from, next) => {
            if (!user) {
                store.dispatch('setCallbackUrl', to.path)
                next({
                    page: '/login'
                })
            }
            next()
        },
        children: [
            ...mainPages
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    let newTime = parseInt(new Date().getTime() / 1000)
    if ((!store.state.user || newTime > store.state.user.jwt.exp) && to.name != 'login') {
        if (store.state.user && newTime > store.state.user.jwt.exp) {
            Message.error('登陆过期 重新登陆');
        }
        store.commit('userLogout');
        next('/login');
    }
    const page = mainPages.find(p => p.name == to.name) // 合法页面
    if (page && !store.state.routesMap.find(p => p.name == to.name)) { // 且未打开
        store.dispatch('updateRoutes', [
            ...store.state.routesMap.filter(p => p.name != to.name),
            {
                name: page.name,
                title: page.title,
                path: to.path
            }
        ])
    }
    next()
})

export default router